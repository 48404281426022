/* eslint-disable react/no-unused-state */
import React from 'react';
import {
  Grid, Divider, Backdrop, CircularProgress,
  Box, List, ListItem, ListItemIcon, ListItemText,
  Button,
} from '@mui/material';
import PropTypes from 'prop-types';

import Pptxgen from 'pptxgenjs';
// import { readRemoteFile } from 'react-papaparse';
import axios from 'axios';

import {
  // SaveOutlined,
  FileDownloadOutlined,
  // AddCircleOutlineOutlined,
  MenuOutlined,
} from '@mui/icons-material';

import '../App.css';
import '../styles/informationPage.css';
import 'bootstrap/dist/css/bootstrap.min.css';

// eslint-disable-next-line no-unused-vars
import AccountAnalysis from './sidebarComponents/accountAnalysis';
import Overview from './sidebarComponents/overview';
import Growth from './sidebarComponents/growth';
import MarginExpansion from './sidebarComponents/marginExpansion';
import DlcPriorities from './sidebarComponents/dlcPriorities';
import CommitmentsToMeta from './sidebarComponents/commitmentsToMeta';
import ValueToMeta from './sidebarComponents/valueToMeta';
import ValueToAcn from './sidebarComponents/valueToAcn';
import ValueToOurPeople from './sidebarComponents/valueToOurPeople';
import SalesExecution from './sidebarComponents/salesExecution';
import CommercialExecution from './sidebarComponents/commercialExecution';
import Filter from './commonComponents/filters';

import * as constantImports from '../constants';

export const CHART_COLORS = {
  red: '#a100ff',
  orange: '#7500c0',
  yellow: '#460073',
  green: '#b455aa',
  blue: '#a055f5',
  purple: '#be82ff',
  grey: '#dcafff',
  crimson: '#e6dcff',
  lightPink: '#FFB6C1',
  lavenderblush: '#8B8386',
  lightblue: '#BCD2EE',
  peacock: '#33A1C9',
};

class InformationPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visibleMenu: false,
      // menuSelected: 'Account Analysis',
      menuSelected: 'Sales Execution',
      selectedIndex: 0,
      subMenuSelected: 'Are we selling effectively?​',
      scrollMenu: 'notScrolledMenu',

      salesExecution: {
        salesGILCPercentage: [],
        salesGILCPercenrtageColumns: [],
        salesGILCDifference: [],
        salesGILCDifferenceColumns: [],
        currentQoQGrowthPercentage: [],
        currentQoQGrowthDifference: [],
        currentQoQGrowth: [],
        currentQoQGrowthColumn: [],
        qualifiedPipeline: [],
        qualifiedPipelineColumn: [],
        unqualifiedPipeline: [],
        unqualifiedPipelineColumn: [],
        planVsProjectedMarket: [],
        planVsProjectedService: [],
        planVsProjectedPortfolio: [],
      },

      fiscalQuarter: '',
      market: '',
      portfolio: '',
      service: '',
      groupBy: 'portfolio',

      backdrop: false,
    };

    this.analysisButtons = this.analysisButtons.bind(this);
    this.getData = this.getData.bind(this);
    // this.readCSV = this.readCSV.bind(this);
    this.clearFilters = this.clearFilters.bind(this);
  }

  componentDidMount() {
    window.addEventListener('scroll', () => {
      let scrollMenu = 'notScrolledMenu';
      if (window.scrollY > 350) {
        scrollMenu = 'scrollMenu';
      }
      this.setState({ scrollMenu });
    });

    this.getData('', '', '', '', 'portfolio');
    // this.readCSV();
  }

  // readCSV() {
  //   this.setState({
  //     backdrop: true
  //   })
  //   readRemoteFile(`https://xk8gb94fq8.execute-api.us-east-1.amazonaws.com/prod/metrics-data/data.csv`, {
  //     downloadRequestHeaders: constantImports.amazonHeader(this.props.idToken),
  //     complete: (results) => {
  //       let salesExecution = {...this.state.salesExecution};

  //       results.data.forEach((element, i) => {
  //         let wrapper = {
  //           filter: element[0],
  //           data: '',
  //           columns: '',
  //           index: ''
  //         }

  //         element.forEach((subelements, j) => {
  //           if(i >= 1 && j >= 1) {
  //             if(subelements !== '') {
  //               console.log(results.data[0][j]);
  //               console.log(subelements);
  //               salesExecution[results.data[0][j]] = subelements
  //             }
  //           }
  //         });
  //       });
        
  //       this.setState({
  //         salesExecution,
  //         backdrop: false
  //       })
  //     },
  //   });
  // }

  getData(fiscalQuarter, market, portfolio, service, groupBy) {
    this.setState({
      backdrop: true,
      groupBy,
      fiscalQuarter,
      market,
      portfolio,
      service,
    });
    const salesExecution = { ...this.state.salesExecution };

    if (this.state.menuSelected === 'Sales Execution') {
      const body = {
        fiscal_quarter: fiscalQuarter,
        market,
        portfolio,
        service,
        group_by: groupBy,
      };

      const instance = axios.create({
        headers: constantImports.amazonHeader(this.props.idToken),
      });

      const salesGILCPercentage = [];
      let salesGILCPercenrtageColumns = [];
      body.metric = 'Sales GILC - Percentage';
      instance.post(`${constantImports.AMAZON_API_BASE_VALUE}/metrics/sales`, body).then((response) => {
        const data = JSON.parse(response.data);
        for (let index = 0; index < data.data.length; index += 1) {
          let dataprc = [];
          data.data[index].forEach(element => {
            if (element !== null) {
              dataprc.push(element * 100);
            } else {
              dataprc.push(element)
            }
          });

          const wrapper = {
            label: data.index[index],
            data: dataprc,
            borderColor: Object.values(CHART_COLORS)[index],
            backgroundColor: Object.values(CHART_COLORS)[index],
          };
          salesGILCPercentage.push(wrapper);
        }
        salesGILCPercenrtageColumns = data.columns;

        salesExecution.salesGILCPercentage = salesGILCPercentage;
        salesExecution.salesGILCPercenrtageColumns = salesGILCPercenrtageColumns;
        this.setState({
          salesExecution,
        });
      });

      const salesGILCDifference = [];
      let salesGILCDifferenceColumns = [];
      body.metric = 'Sales GILC - Difference';
      instance.post(`${constantImports.AMAZON_API_BASE_VALUE}/metrics/sales`, body).then((response) => {
        const data = JSON.parse(response.data);
        for (let index = 0; index < data.data.length; index += 1) {
          const wrapper = {
            label: data.index[index],
            data: data.data[index],
            borderColor: Object.values(CHART_COLORS)[index],
            backgroundColor: Object.values(CHART_COLORS)[index],
          };
          salesGILCDifference.push(wrapper);
        }
        salesGILCDifferenceColumns = data.columns;

        salesExecution.salesGILCDifference = salesGILCDifference;
        salesExecution.salesGILCDifferenceColumns = salesGILCDifferenceColumns;
        this.setState({
          salesExecution,
        });
      });

      let currentQoQGrowthPercentage = [];
      body.metric = 'Growth Last Quarter Card - Percentage';
      instance.post(`${constantImports.AMAZON_API_BASE_VALUE}/metrics/sales`, body).then((response) => {
        const data = JSON.parse(response.data);
        [currentQoQGrowthPercentage] = data.data;

        salesExecution.currentQoQGrowthPercentage = currentQoQGrowthPercentage;
        this.setState({
          salesExecution,
        });
      });

      let currentQoQGrowthDifference = [];
      body.metric = 'Growth Last Quarter Card - Difference';
      instance.post(`${constantImports.AMAZON_API_BASE_VALUE}/metrics/sales`, body).then((response) => {
        const data = JSON.parse(response.data);
        [currentQoQGrowthDifference] = data.data;

        salesExecution.currentQoQGrowthDifference = currentQoQGrowthDifference;
        this.setState({
          salesExecution,
        });
      });

      const currentQoQGrowth = [];
      let currentQoQGrowthColumn = [];
      body.metric = 'Growth Last Quarter';
      instance.post(`${constantImports.AMAZON_API_BASE_VALUE}/metrics/sales`, body).then((response) => {
        const data = JSON.parse(response.data);
        for (let index = 0; index < data.data.length; index += 1) {
          const wrapper = {
            label: data.index[index],
            data: [data.data[index] * 100],
            borderColor: Object.values(CHART_COLORS)[index],
            backgroundColor: Object.values(CHART_COLORS)[index],
          };
          currentQoQGrowth.push(wrapper);
        }
        currentQoQGrowthColumn = [data.name];

        salesExecution.currentQoQGrowth = currentQoQGrowth;
        salesExecution.currentQoQGrowthColumn = currentQoQGrowthColumn;
        this.setState({
          salesExecution,
        });
      });
      

      const qualifiedPipeline = [];
      let qualifiedPipelineColumn = [];
      body.metric = 'Qualified Pipeline';
      instance.post(`${constantImports.AMAZON_API_BASE_VALUE}/metrics/sales`, body).then((response) => {
        const data = JSON.parse(response.data);
        for (let index = 0; index < data.data.length; index += 1) {
          const wrapper = {
            label: data.index[index],
            data: data.data[index],
            borderColor: Object.values(CHART_COLORS)[index],
            backgroundColor: Object.values(CHART_COLORS)[index],
          };
          qualifiedPipeline.push(wrapper);
        }
        qualifiedPipelineColumn = data.columns;

        salesExecution.qualifiedPipeline = qualifiedPipeline;
        salesExecution.qualifiedPipelineColumn = qualifiedPipelineColumn;
        this.setState({
          salesExecution,
        });
      });

      const unqualifiedPipeline = [];
      let unqualifiedPipelineColumn = [];
      body.metric = 'Unqualified Pipeline';
      instance.post(`${constantImports.AMAZON_API_BASE_VALUE}/metrics/sales`, body).then((response) => {
        const data = JSON.parse(response.data);
        for (let index = 0; index < data.data.length; index += 1) {
          const wrapper = {
            label: data.index[index],
            data: data.data[index],
            borderColor: Object.values(CHART_COLORS)[index],
            backgroundColor: Object.values(CHART_COLORS)[index],
          };
          unqualifiedPipeline.push(wrapper);
        }
        unqualifiedPipelineColumn = data.columns;

        salesExecution.unqualifiedPipeline = unqualifiedPipeline;
        salesExecution.unqualifiedPipelineColumn = unqualifiedPipelineColumn;
        this.setState({
          salesExecution,
        });
      });

      let planVsProjectedMarket = [];
      let planVsProjectedService = [];
      let planVsProjectedPortfolio = [];
      body.metric = 'Actual vs Plan vs Projected Landing Position';
      body.group_by = 'market';
      body.market = '';
      instance.post(`${constantImports.AMAZON_API_BASE_VALUE}/metrics/sales`, body).then((response) => {
        const data = JSON.parse(response.data);
        for (let index = 0; index < data.data.length; index += 1) {
          const wrapper = [data.index[index], data.data[index][0], data.data[index][1], data.data[index][2], data.data[index][3]];
          data.data[index] = wrapper;
        }
        planVsProjectedMarket = data.data;
        salesExecution.planVsProjectedMarket = planVsProjectedMarket;
        this.setState({
          salesExecution,
        });
      });

      body.group_by = 'portfolio';
      body.market = market;
      body.portfolio = '';
      instance.post(`${constantImports.AMAZON_API_BASE_VALUE}/metrics/sales`, body).then((response) => {
        const data = JSON.parse(response.data);
        for (let index = 0; index < data.data.length; index += 1) {
          const wrapper = [data.index[index], data.data[index][0], data.data[index][1], data.data[index][2], data.data[index][3]];
          data.data[index] = wrapper;
        }
        planVsProjectedPortfolio = data.data;
        salesExecution.planVsProjectedPortfolio = planVsProjectedPortfolio;
        this.setState({
          salesExecution,
        });
      });

      body.group_by = 'service';
      body.portfolio = portfolio;
      body.service = '';
      instance.post(`${constantImports.AMAZON_API_BASE_VALUE}/metrics/sales`, body).then((response) => {
        const data = JSON.parse(response.data);
        for (let index = 0; index < data.data.length; index += 1) {
          const wrapper = [data.index[index], data.data[index][0], data.data[index][1], data.data[index][2], data.data[index][3]];
          data.data[index] = wrapper;
        }
        planVsProjectedService = data.data;
        salesExecution.planVsProjectedService = planVsProjectedService;
        this.setState({
          salesExecution,
          backdrop: false,
        });
      });
    }
  }

  getIcon(index, tabname) {
    switch (index) {
      case 0:
        return (<img src="imgs/icons/overview.svg" height={24} alt="Overview" className={`${(this.state.menuSelected === tabname) ? 'color-white' : ''}`} />);
      case 1:
        return (<img src="imgs/icons/growth.svg" height={24} alt="Growth" className={`${(this.state.menuSelected === tabname) ? 'color-white' : ''}`} />);
      case 2:
        return (<img src="imgs/icons/margin-expansion.svg" height={24} alt="Margin Expansion" className={`${(this.state.menuSelected === tabname) ? 'color-white' : ''}`} />);
      case 3:
        return (<img src="imgs/icons/dlc.svg" height={24} alt="DLC Priorities" className={`${(this.state.menuSelected === tabname) ? 'color-white' : ''}`} />);
      case 4:
        return (<img src="imgs/icons/commitments-to-meta.svg" height={24} alt="Commitments to Meta" className={`${(this.state.menuSelected === tabname) ? 'color-white' : ''}`} />);
      case 5:
        return (<img src="imgs/icons/value-to-meta.svg" height={24} alt="Value to Meta" className={`${(this.state.menuSelected === tabname) ? 'color-white' : ''}`} />);
      case 6:
        return (<img src="imgs/icons/value-to-accenture.svg" height={24} alt="Value to Accenture" className={`${(this.state.menuSelected === tabname) ? 'color-white' : ''}`} />);
      case 7:
        return (<img src="imgs/icons/value-to-our-people.svg" height={24} alt="Value to Our People" className={`${(this.state.menuSelected === tabname) ? 'color-white' : ''}`} />);
      case 8:
        return (<img src="imgs/icons/sales-execution.svg" height={24} alt="Sales Execution" className={`${(this.state.menuSelected === tabname) ? 'color-white' : ''}`} />);
      case 9:
        return (<img src="imgs/icons/commercial-execution.svg" height={24} alt="Commercial Execution" className={`${(this.state.menuSelected === tabname) ? 'color-white' : ''}`} />);
      default:
        return (<span />);
    }
  }

  getComponent() {
    switch (this.state.menuSelected) {
      case 'Overview':
        return (<Overview />);
      case 'Growth':
        return (<Growth />);
      case 'Margin Expansion':
        return (<MarginExpansion />);
      case 'DLC Priorities':
        return (<DlcPriorities />);
      case 'Commitments to Meta':
        return (<CommitmentsToMeta />);
      case 'Value to Meta':
        return (<ValueToMeta />);
      case 'Value to ACN':
        return (<ValueToAcn />);
      case 'Value to our people':
        return (<ValueToOurPeople />);
      case 'Sales Execution':
        return (<SalesExecution salesExecutionData={this.state.salesExecution} getData={this.getData} fiscalQuarter={this.state.fiscalQuarter} market={this.state.market} portfolio={this.state.portfolio} service={this.state.service} groupBy={this.state.groupBy} />);
      case 'Commercial Execution':
        return (<CommercialExecution />);
      default:
        return (<span />);
    }
  }

  async clearFilters() {
    await this.setState({
      fiscalQuarter: '',
      portfolio: '',
      market: '',
      service: '',
    });

    this.getData(this.state.portfolio, this.state.fiscalQuarter, this.state.market, this.state.service, this.state.groupBy);
  }

  analysisButtons(name, subname) {
    this.setState({
      menuSelected: name,
      subMenuSelected: subname,
    });
  }

  prevButton(index) {
    const currentTab = index - 1;
    let menuSelected = '';
    let subMenuSelected = '';

    if (currentTab > 0) {
      const prevTab = constantImports.menuTabs[currentTab - 1];
      menuSelected = prevTab.name;
      subMenuSelected = prevTab.subname;
    } else {
      menuSelected = 'Account Analysis';
      subMenuSelected = '';
    }

    this.setState({
      menuSelected,
      subMenuSelected,
      selectedIndex: currentTab,
    });
  }

  nextButton(index) {
    const currentTab = index - 1;
    const nextTab = constantImports.menuTabs[currentTab + 1];
    const menuSelected = nextTab.name;
    const subMenuSelected = nextTab.subname;

    this.setState({
      menuSelected,
      subMenuSelected,
      selectedIndex: index + 1,
    });
  }

  downloadReport() {
    // Create a presentation
    const pres = new Pptxgen();

    // Add a Slide
    const slide = pres.addSlide('Title Slide');
    slide.background = { color: 'F1F1F1' }; // Solid color
    slide.color = 'CF0101'; // Set slide default font color
    slide.slideNumber = { x: 2.0, y: '80%' };

    const textboxText = 'Hello World from PptxGenJS!';
    const textboxOpts = { x: 1, y: 1, color: '363636' };
    const dataChartAreaLine = [
      {
        name: 'Actual Sales',
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        values: [1500, 4600, 5156, 3167, 8510, 8009, 6006, 7855, 12102, 12789, 10123, 15121],
      },
      {
        name: 'Projected Sales',
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        values: [1000, 2600, 3456, 4567, 5010, 6009, 7006, 8855, 9102, 10789, 11123, 12121],
      },
    ];
    slide.addText(textboxText, textboxOpts);
    slide.addChart(pres.ChartType.line, dataChartAreaLine, {
      x: 1.1, y: 1.1, w: 6, h: 3,
    });

    slide.addNotes('TEXT');

    // Metadata properties
    pres.author = 'Meta';
    pres.company = 'Meta';
    pres.revision = '2022';
    pres.subject = 'Report';
    pres.title = 'Report Sample Presentation';

    // Save presentation
    pres.writeFile({ fileName: 'Report-Sample.pptx' });
  }

  render() {
    return (
      <div>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={this.state.backdrop}
          onClick={() => { this.setState({ backdrop: false }); }}
        >
          <CircularProgress color="inherit" />
        </Backdrop>

        <Grid container id="informationPage">
          <Grid container className="informationMenu">
            <Grid item xs={12}>
              {/* <Link to="/">
                <p>
                  <ArrowBackOutlined />
                  {' '}
                  Back to Search
                </p>
              </Link> */}
              <h1>Account Performance Insights</h1>
            </Grid>
            <Grid item xs={8}>
              <h3>{this.state.menuSelected}</h3>
              <h4>{this.state.subMenuSelected}</h4>
            </Grid>
            <Grid item xs={4} className="flex iconsHeader">
              {/* <SaveOutlined className="cursor-pointer" sx={{ fontSize: 50 }} onClick={() => { console.log('Save'); }} /> */}
              <FileDownloadOutlined className="cursor-pointer" sx={{ fontSize: 50 }} onClick={this.downloadReport} />
              {/* <AddCircleOutlineOutlined className="cursor-pointer" sx={{ fontSize: 50 }} onClick={() => { console.log('Add'); }} /> */}
            </Grid>
            <Grid item xs={12} className="flex" sx={{ textAlign: 'right', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
              {this.state.selectedIndex > 0 ? <Button className="fontsize-14" onClick={() => { this.prevButton(this.state.selectedIndex); }}>Prev</Button> : ''}
              {this.state.selectedIndex < constantImports.menuTabs.length ? <Button className="fontsize-14" onClick={() => { this.nextButton(this.state.selectedIndex); }}>Next</Button> : ''}
            </Grid>
            <Grid item xs={12}>
              <Divider className="colorHr" />
            </Grid>
            <Grid item xs={12} sx={{ padding: '15px 40px 10px 40px' }}>
              <Filter
                type=""
                getData={this.getData}
                groupBy={this.state.groupBy}
                portfolio={this.state.portfolio}
                market={this.state.market}
                service={this.state.service}
                fiscalQuarter={this.state.fiscalQuarter}
                fiscalQuarterOptions={this.props.filters.fiscal_quarter}
                marketOptions={this.props.filters.market}
                portfolioOptions={this.props.filters.portfolio}
                serviceOptions={this.props.filters.service}
                clearFilters={this.clearFilters}
              />
            </Grid>
          </Grid>

          <Grid container className={`${this.state.scrollMenu} informationMenuMovement`}>
            <Grid item xs={8}>
              {/* <h1>Account Performance Insights</h1> */}
              <br />
              <h2>{this.state.menuSelected}</h2>
              {/* <h3>{this.state.subMenuSelected}</h3> */}
            </Grid>
            {/* <Grid item xs={4} className="flex iconsHeader">
              <SaveOutlined className="cursor-pointer" sx={{ fontSize: 50 }} onClick={() => { console.log('Save'); }} />
              <FileDownloadOutlined className="cursor-pointer" sx={{ fontSize: 50 }} onClick={this.downloadReport} />
              <AddCircleOutlineOutlined className="cursor-pointer" sx={{ fontSize: 50 }} onClick={() => { console.log('Add'); }} />
            </Grid> */}
            <Grid item xs={12}>
              <Filter
                type="white-button"
                getData={this.getData}
                groupBy={this.state.groupBy}
                portfolio={this.state.portfolio}
                market={this.state.market}
                service={this.state.service}
                fiscalQuarter={this.state.fiscalQuarter}
                fiscalQuarterOptions={this.props.filters.fiscal_quarter}
                marketOptions={this.props.filters.market}
                portfolioOptions={this.props.filters.portfolio}
                serviceOptions={this.props.filters.service}
                clearFilters={this.clearFilters}
              />
            </Grid>
          </Grid>

          <Grid item xs={12} className="informationContainer">
            <Grid container sx={{ padding: '5px 40px 40px 40px' }}>
              <Grid item xs={12}>
                {this.state.subMenuSelected === '' ? <AccountAnalysis analysisButtons={this.analysisButtons} /> : this.getComponent()}
              </Grid>
            </Grid>

            <Grid container sx={{ minHeight: '80vh', position: 'relative' }} className="sideBar">
              <Box sx={{ maxWidth: 360 }} className="menu">
                <nav>
                  <List onMouseOver={() => this.setState({ visibleMenu: true })} onMouseLeave={() => this.setState({ visibleMenu: false })}>
                    <ListItem className={`${(this.state.menuSelected === 'Account Analysis') ? 'activeSideBar' : ''}`} onClick={() => { this.setState({ menuSelected: 'Account Analysis', subMenuSelected: '', selectedIndex: 0 }); }}>
                      <ListItemIcon>
                        <MenuOutlined className={`${(this.state.menuSelected === 'Account Analysis') ? 'color-white' : ''}`} />
                      </ListItemIcon>
                      {this.state.visibleMenu ? <ListItemText sx={{ paddingLeft: '15px' }} primary="ACCOUNT ANALYSIS" /> : ''}
                    </ListItem>

                    {constantImports.menuTabs.map((tab, index) => (
                      <>
                        <Divider />
                        <ListItem key={tab.name} className={`${(this.state.menuSelected === tab.name) ? 'activeSideBar' : ''}`} disableGutters sx={{ cursor: 'pointer' }} onClick={() => { this.setState({ menuSelected: tab.name, subMenuSelected: tab.subname, selectedIndex: index + 1 }); }}>
                          <ListItemIcon>
                            {this.getIcon(index, tab.name)}
                          </ListItemIcon>
                          {this.state.visibleMenu ? <ListItemText sx={{ paddingLeft: '15px' }} primary={tab.name} /> : ''}
                        </ListItem>
                      </>
                    ))}
                  </List>
                </nav>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}

InformationPage.propTypes = {
  filters: PropTypes.objectOf(PropTypes.array).isRequired,
  idToken: PropTypes.string.isRequired,
};

export default InformationPage;
