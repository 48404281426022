import React from 'react';
import PropTypes from 'prop-types';
import {
  Modal, Box, Button, Grid, FormControl, FormLabel, OutlinedInput, Rating, Select,
  MenuItem, Checkbox, ListItemText, RadioGroup, FormControlLabel, Radio, TextField, Snackbar, CircularProgress,
} from '@mui/material';

import axios from 'axios';

import { Link } from 'react-router-dom';
import '../App.css';

import { Star, Close } from '@mui/icons-material';
import * as constants from '../constants';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 700,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  borderTop: '8px solid #a100ff',
  boxShadow: 24,
  p: 4,
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

class Feedback extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      roleOnAccenture: '',
      rating: 0,
      topPages: [],
      businessObjectives: '',
      otherOption: '',
      additionalFeedback: '',
      snackbar: false,
      loadingSubmit: false,

      options: [
        // 'Current State Overview',
        // 'Growth',
        // 'Margin Expansion',
        // 'DLC Priorities',
        // 'Commitments to Meta',
        // 'Value to Meta',
        // 'Value to ACN',
        // 'Value to our People',
        'Sales Execution',
        // 'Commercial Execution',
        // 'Additional Priorities',
        // 'Report Builder',
        'Other',
      ],

      allRequirementsButton: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.submitForm = this.submitForm.bind(this);
  }

  async handleChange(e) {
    const { name } = e.target;
    const { value } = e.target;

    if (name === 'businessObjectives') {
      await this.setState({
        businessObjectives: null,
        otherOption: '',
      });
    }
    if (e.target.type === 'text') {
      if (value.length > 1000) {
        return;
      }
    }

    await this.setState({
      [name]: value,
    });

    this.allRequirementsValidation();
  }

  allRequirementsValidation() {
    let validation = false;

    if (this.state.roleOnAccenture.length > 0 && this.state.rating > 0 && this.state.topPages.length > 0 && this.state.businessObjectives.length > 0) {
      validation = true;
    }

    this.setState({
      allRequirementsButton: validation,
    });
  }

  submitForm() {
    const body = {
      type: 'Feedback',
      roleOnAccenture: this.state.roleOnAccenture,
      rating: this.state.rating,
      topPages: this.state.topPages,
      businessObjectives: this.state.businessObjectives,
      otherOption: this.state.otherOption,
      additionalFeedback: this.state.additionalFeedback,
    };

    this.setState({
      loadingSubmit: true,
      allRequirementsButton: false,
    });

    const instance = axios.create({
      headers: constants.amazonHeader(this.props.idToken),
    });
    instance.post(`${constants.AMAZON_API_BASE_VALUE}/forms`, body).then(() => {
      this.setState({
        snackbar: true,
        loadingSubmit: true,
        allRequirementsButton: false,
      });

      setTimeout(() => {
        this.props.closeModal();
      }, 5000);
    });
  }

  render() {
    return (
      <div id="feedback">
        <Modal
          open
          onClose={this.props.closeModal}
        >
          <Box sx={style}>
            <Grid container>
              <Grid item xs={12} className="flex flex-space-between">
                <h3 id="unstyled-modal-title">Feedback Form</h3>
                <Close className="cursor-pointer" onClick={this.props.closeModal} />
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth className="margin-bottom-20">
                  <FormLabel>Would you like to submit a bug or enhancement request?</FormLabel>
                  <Link to="/contact-us">
                    <Button className="button-purple button-feedback-modal" variant="contained" onClick={this.props.closeModal}>Contact Us Form</Button>
                  </Link>
                </FormControl>

                <FormControl fullWidth className="margin-bottom-20">
                  <FormLabel>
                    What is your role on the Meta Account?
                    {' '}
                    <span className="mandatory">*</span>
                  </FormLabel>
                  <OutlinedInput
                    name="roleOnAccenture"
                    variant="outlined"
                    value={this.state.roleOnAccenture}
                    onChange={this.handleChange}
                    inputProps={{ maxLength: 1000 }}
                  />
                </FormControl>

                <FormControl fullWidth className="margin-bottom-20">
                  <FormLabel>
                    Please rate your overall experience with the Account Performance Insights (API)
                    {' '}
                    <span className="mandatory">*</span>
                  </FormLabel>
                  <Rating
                    name="rating"
                    value={this.state.rating}
                    onChange={this.handleChange}
                    emptyIcon={<Star style={{ opacity: 0.55 }} fontSize="inherit" />}
                  />
                </FormControl>

                <FormControl fullWidth className="margin-bottom-20">
                  <FormLabel>
                    What are the top Account Insights pages you use to support your business objectives?
                    {' '}
                    <span className="mandatory">*</span>
                  </FormLabel>
                  <Select
                    name="topPages"
                    multiple
                    value={this.state.topPages}
                    onChange={this.handleChange}
                    input={<OutlinedInput label="Tag" />}
                    renderValue={(selected) => selected.join(', ')}
                    MenuProps={MenuProps}
                  >
                    {this.state.options.map((name) => (
                      <MenuItem key={name} value={name}>
                        <Checkbox checked={this.state.topPages.indexOf(name) > -1} />
                        <ListItemText primary={name} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <FormControl fullWidth className="margin-bottom-20">
                  <FormLabel>
                    How does Account Insights help support your business objectives? Please select all that apply
                    {' '}
                    <span className="mandatory">*</span>
                  </FormLabel>
                  <RadioGroup value={this.state.businessObjectives} onChange={this.handleChange} name="businessObjectives">
                    <FormControlLabel
                      value="Viewing metrics across multiple domains within one platform"
                      control={(
                        <Radio size="small" />)}
                      label="Viewing metrics across multiple domains within one platform"
                    />
                    <FormControlLabel
                      value="Viewing metrics curated to answer key business questions"
                      control={(
                        <Radio size="small" />)}
                      label="Viewing metrics curated to answer key business questions"
                    />
                    {/* <FormControlLabel
                      value="Creating customized reports with the visualizations I am most interested in"
                      control={(
                        <Radio size="small" />)}
                      label="Creating customized reports with the visualizations I am most interested in"
                    />
                    <FormControlLabel
                      value="Creating a Watchlist of visualizations for quick reference"
                      control={(
                        <Radio size="small" />)}
                      label="Creating a Watchlist of visualizations for quick reference"
                    /> */}
                    <FormControlLabel
                      value="Downloading reports for use outside the platform"
                      control={(
                        <Radio size="small" />)}
                      label="Downloading reports for use outside the platform"
                    />
                    <FormControlLabel
                      value={this.state.otherOption}
                      control={(
                        <Radio checked={this.state.otherOption.length > 0} size="small" />
                      )}
                      label={(
                        <TextField
                          name="otherOption"
                          variant="standard"
                          placeholder="Other"
                          value={this.state.otherOption}
                          onChange={this.handleChange}
                          inputProps={{ maxLength: 1000 }}
                        />
                      )}
                    />
                  </RadioGroup>
                </FormControl>

                <FormControl fullWidth className="margin-bottom-20">
                  <FormLabel>
                    Any additional feedback?
                  </FormLabel>
                  <OutlinedInput
                    name="additionalFeedback"
                    variant="outlined"
                    value={this.state.additionalFeedback}
                    onChange={this.handleChange}
                    inputProps={{ maxLength: 1000 }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <p className="fontsize-12 margin-bottom-20">
                  Note: Fields marked with an
                  {' '}
                  <span className="mandatory">*</span>
                  {' '}
                  are mandatory
                </p>
              </Grid>
              <Grid item xs={12} className="flex flex-center relative">
                <Button className={`button-purple ${!this.state.allRequirementsButton ? 'button-disabled' : ''}`} disabled={!this.state.allRequirementsButton} variant="contained" onClick={this.submitForm}>Submit</Button>
                {this.state.loadingSubmit && (
                  <CircularProgress
                    size={24}
                    sx={{
                      color: '#a100ff',
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      marginTop: '-12px',
                      marginLeft: '-12px',
                    }}
                  />
                )}
              </Grid>
            </Grid>
          </Box>
        </Modal>
        <Snackbar
          open={this.state.snackbar}
          autoHideDuration={5000}
          onClose={() => { this.setState({ snackbar: false }); }}
          message="Submitted successfully"
        />
      </div>
    );
  }
}

Feedback.propTypes = {
  closeModal: PropTypes.func.isRequired,
};

export default Feedback;
