import React from 'react';
import { Skeleton } from '@mui/material';

import '../../App.css';

class myWatchlist extends React.Component {
  render() {
    return (
      <div>
        <div>
          <h3>My Watchlist</h3>
          <Skeleton variant="rectangular" width={500} height={100} />
        </div>
      </div>
    );
  }
}

export default myWatchlist;
