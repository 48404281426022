import React from 'react';
import {
  Grid, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Button, FormGroup, Checkbox, OutlinedInput, Snackbar, TextField,
  CircularProgress,
} from '@mui/material';

import axios from 'axios';
import * as constants from '../constants';

import '../App.css';
import '../styles/homePage.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import IntroductionBanner from './commonComponents/introductionBanner';
import * as constantImports from './../constants';

class ContactUs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      agreementRatio: null,
      natureRequest: null,

      generalInformation: null,

      currentStateOverviewBug: false,
      growthBug: false,
      marginExpansionBug: false,
      dlcPrioritiesBug: false,
      commitmentsToMetaBug: false,
      valueToMetaBug: false,
      valueToAcnBug: false,
      valueToOurPeopleBug: false,
      salesExecutionBug: false,
      commercialExecutionBug: false,
      additionalPrioritiesBug: false,
      reportBuilderBug: false,
      otherBug: false,
      otherBugText: '',
      bugDescription: null,
      bugImpact: null,
      timeBugCorrected: null,
      bugAdditionalInfo: '',

      currentStateOverviewEnhance: false,
      growthEnhance: false,
      marginExpansionEnhance: false,
      dlcPrioritiesEnhance: false,
      commitmentsToMetaEnhance: false,
      valueToMetaEnhance: false,
      valueToAcnEnhance: false,
      valueToOurPeopleEnhance: false,
      salesExecutionEnhance: false,
      commercialExecutionEnhance: false,
      additionalPrioritiesEnhance: false,
      reportBuilderEnhance: false,
      otherEnhance: false,
      otherEnhanceText: '',
      enhanceDescription: null,
      enhanceImpact: null,
      timeForEnhance: null,
      enhanceAdditionalInfo: '',

      allRequirementsButton: false,

      snackbar: false,
      loadingSubmit: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.submitForm = this.submitForm.bind(this);
  }

  async handleChange(e) {
    const { name } = e.target;
    let { value } = e.target;

    if (name === 'agreementRatio') {
      value = (value === 'true');
    }
    if (e.target.type === 'text') {
      if (value.length > 1000) {
        return;
      }
    }
    if (e.target.type === 'checkbox') {
      value = e.target.checked;
    }

    await this.setState({
      [name]: value,
    });

    this.allRequirementsValidation();
  }

  allRequirementsValidation() {
    let validation = false;
    if (this.state.agreementRatio) {
      if (this.state.natureRequest === 'generate information') {
        if (this.state.generalInformation.length > 0) {
          validation = true;
        }
      }
      if (this.state.natureRequest === 'bug fix') {
        if ((this.state.currentStateOverviewBug === true || this.state.growthBug === true || this.state.marginExpansionBug === true || this.state.dlcPrioritiesBug === true || this.state.commitmentsToMetaBug === true
            || this.state.valueToMetaBug === true || this.state.valueToAcnBug === true || this.state.valueToOurPeopleBug === true || this.state.salesExecutionBug === true || this.state.commercialExecutionBug === true
            || this.state.additionalPrioritiesBug === true || this.state.reportBuilderBug === true || this.state.otherBug === true) && this.state.bugDescription.length > 0 && this.state.bugImpact.length > 0 && this.state.timeBugCorrected.length > 0) {
          validation = true;
        }
      }
      if (this.state.natureRequest === 'enhacement request') {
        if ((this.state.currentStateOverviewEnhance === true || this.state.growthEnhance === true || this.state.marginExpansionEnhance === true || this.state.dlcPrioritiesEnhance === true || this.state.commitmentsToMetaEnhance === true
            || this.state.valueToMetaEnhance === true || this.state.valueToAcnEnhance === true || this.state.valueToOurPeopleEnhance === true || this.state.salesExecutionEnhance === true || this.state.commercialExecutionEnhance === true
            || this.state.additionalPrioritiesEnhance === true || this.state.reportBuilderEnhance === true || this.state.otherEnhance === true) && this.state.enhanceDescription.length > 0 && this.state.enhanceImpact.length > 0 && this.state.timeForEnhance.length > 0) {
          validation = true;
        }
      }
    }

    this.setState({
      allRequirementsButton: validation,
    });
  }

  submitForm() {
    const body = {
      type: 'Contact Us',
      natureRequest: this.state.natureRequest,
    };
    if (this.state.natureRequest === 'generate information') {
      body.generalInformation = this.state.generalInformation;
    }
    if (this.state.natureRequest === 'bug fix') {
      body.currentStateOverviewBug = this.state.currentStateOverviewBug;
      body.growthBug = this.state.growthBug;
      body.marginExpansionBug = this.state.marginExpansionBug;
      body.dlcPrioritiesBug = this.state.dlcPrioritiesBug;
      body.commitmentsToMetaBug = this.state.commitmentsToMetaBug;
      body.valueToMetaBug = this.state.valueToMetaBug;
      body.valueToAcnBug = this.state.valueToAcnBug;
      body.valueToOurPeopleBug = this.state.valueToOurPeopleBug;
      body.salesExecutionBug = this.state.salesExecutionBug;
      body.commercialExecutionBug = this.state.commercialExecutionBug;
      body.additionalPrioritiesBug = this.state.additionalPrioritiesBug;
      body.reportBuilderBug = this.state.reportBuilderBug;
      body.otherBug = this.state.otherBugText;
      body.bugDescription = this.state.bugDescription;
      body.bugImpact = this.state.bugImpact;
      body.timeBugCorrected = this.state.timeBugCorrected;
      body.bugAdditionalInfo = this.state.bugAdditionalInfo;
    }
    if (this.state.natureRequest === 'enhacement request') {
      body.currentStateOverviewEnhance = this.state.currentStateOverviewEnhance;
      body.growthEnhance = this.state.growthEnhance;
      body.marginExpansionEnhance = this.state.marginExpansionEnhance;
      body.dlcPrioritiesEnhance = this.state.dlcPrioritiesEnhance;
      body.commitmentsToMetaEnhance = this.state.commitmentsToMetaEnhance;
      body.valueToMetaEnhance = this.state.valueToMetaEnhance;
      body.valueToAcnEnhance = this.state.valueToAcnEnhance;
      body.valueToOurPeopleEnhance = this.state.valueToOurPeopleEnhance;
      body.salesExecutionEnhance = this.state.salesExecutionEnhance;
      body.commercialExecutionEnhance = this.state.commercialExecutionEnhance;
      body.additionalPrioritiesEnhance = this.state.additionalPrioritiesEnhance;
      body.reportBuilderEnhance = this.state.reportBuilderEnhance;
      body.otherEnhance = this.state.otherEnhanceText;
      body.enhanceDescription = this.state.enhanceDescription;
      body.enhanceImpact = this.state.enhanceImpact;
      body.timeForEnhance = this.state.timeForEnhance;
      body.enhanceAdditionalInfo = this.state.enhanceAdditionalInfo;
    }

    this.setState({
      loadingSubmit: true,
      allRequirementsButton: false,
    });

    const instance = axios.create({
      headers: constantImports.amazonHeader(this.props.idToken),
    });
    instance.post(`${constants.AMAZON_API_BASE_VALUE}/forms`, body).then(() => {
      this.setState({
        snackbar: true,
        loadingSubmit: false,
        allRequirementsButton: true,
      });
    });
  }

  generalInputForm() {
    return (
      <FormControl className="margin-bottom-20" variant="standard" fullWidth>
        <FormLabel>
          3. Please enter the details of your General Information request.
          {' '}
          <span className="mandatory">*</span>
        </FormLabel>
        <OutlinedInput
          name="generalInformation"
          variant="outlined"
          value={this.state.generalInformation}
          onChange={this.handleChange}
          inputProps={{ maxLength: 1000 }}
        />
      </FormControl>
    );
  }

  bugInputForm() {
    return (
      <>
        <FormControl fullWidth className="margin-bottom-20">
          <FormLabel>
            3. What Page(s) have the bug?
            {' '}
            <span className="mandatory"> *</span>
          </FormLabel>
          <FormGroup>
            {/* <FormControlLabel
              control={
                <Checkbox checked={this.state.currentStateOverviewBug} onChange={this.handleChange} name="currentStateOverviewBug" />
              }
              label="Current State Overview"
            />
            <FormControlLabel
              control={
                <Checkbox checked={this.state.growthBug} onChange={this.handleChange} name="growthBug" />
              }
              label="Growth"
            />
            <FormControlLabel
              control={
                <Checkbox checked={this.state.marginExpansionBug} onChange={this.handleChange} name="marginExpansionBug" />
              }
              label="Margin Expansion"
            />
            <FormControlLabel
              control={
                <Checkbox checked={this.state.dlcPrioritiesBug} onChange={this.handleChange} name="dlcPrioritiesBug" />
              }
              label="DLC Priorities"
            />
            <FormControlLabel
              control={
                <Checkbox checked={this.state.commitmentsToMetaBug} onChange={this.handleChange} name="commitmentsToMetaBug" />
              }
              label="Commitments to Meta"
            />
            <FormControlLabel
              control={
                <Checkbox checked={this.state.valueToMetaBug} onChange={this.handleChange} name="valueToMetaBug" />
              }
              label="Value to Meta"
            />
            <FormControlLabel
              control={
                <Checkbox checked={this.state.valueToAcnBug} onChange={this.handleChange} name="valueToAcnBug" />
              }
              label="Value to ACN"
            />
            <FormControlLabel
              control={
                <Checkbox checked={this.state.valueToOurPeopleBug} onChange={this.handleChange} name="valueToOurPeopleBug" />
              }
              label="Value to our People"
            /> */}
            <FormControlLabel
              control={
                <Checkbox checked={this.state.salesExecutionBug} onChange={this.handleChange} name="salesExecutionBug" />
              }
              label="Sales Execution"
            />
            {/* <FormControlLabel
              control={
                <Checkbox checked={this.state.commercialExecutionBug} onChange={this.handleChange} name="commercialExecutionBug" />
              }
              label="Commercial Execution"
            />
            <FormControlLabel
              control={
                <Checkbox checked={this.state.additionalPrioritiesBug} onChange={this.handleChange} name="additionalPrioritiesBug" />
              }
              label="Additional Priorities"
            />
            <FormControlLabel
              control={
                <Checkbox checked={this.state.reportBuilderBug} onChange={this.handleChange} name="reportBuilderBug" />
              }
              label="Report Builder"
            /> */}
            <FormControlLabel
              control={(
                <>
                  <Checkbox checked={this.state.otherBug} onChange={this.handleChange} name="otherBug" />
                  <TextField
                    name="otherBugText"
                    variant="standard"
                    placeholder="Other"
                    value={this.state.otherBugText}
                    onChange={this.handleChange}
                    inputProps={{ maxLength: 1000 }}
                  />
                </>
              )}
              label=""
            />
          </FormGroup>
        </FormControl>
        <FormControl className="margin-bottom-20" variant="standard" fullWidth>
          <FormLabel>
            4. Please describe what you are currently seeing vs. what you expect to see.
            {' '}
            <span className="mandatory"> *</span>
          </FormLabel>
          <OutlinedInput
            name="bugDescription"
            variant="outlined"
            value={this.state.bugDescription}
            onChange={this.handleChange}
            inputProps={{ maxLength: 1000 }}
          />
        </FormControl>
        <FormControl className="margin-bottom-20" variant="standard" fullWidth>
          <FormLabel>
            5. What is the business impact of this bug?
            {' '}
            <span className="mandatory"> *</span>
          </FormLabel>
          <OutlinedInput
            name="bugImpact"
            variant="outlined"
            value={this.state.bugImpact}
            onChange={this.handleChange}
            inputProps={{ maxLength: 1000 }}
          />
        </FormControl>
        <FormControl className="margin-bottom-20" fullWidth>
          <FormLabel>
            6. When do you need this bug corrected by?
            {' '}
            <span className="mandatory"> *</span>
          </FormLabel>
          <RadioGroup value={this.state.timeBugCorrected} onChange={this.handleChange} name="timeBugCorrected">
            <FormControlLabel
              value="2 days"
              control={(
                <Radio size="small" />)}
              label="Within 2 days"
            />
            <FormControlLabel
              value="1 week"
              control={(<Radio size="small" />)}
              label="Within 1 week"
            />
            <FormControlLabel
              value="1 month"
              control={(<Radio size="small" />)}
              label="Within 1 month"
            />
          </RadioGroup>
        </FormControl>
        <FormControl className="margin-bottom-20" variant="standard" fullWidth>
          <FormLabel>7. (Optional) Please include any additional relevant information.</FormLabel>
          <OutlinedInput
            name="bugAdditionalInfo"
            variant="outlined"
            value={this.state.bugAdditionalInfo}
            onChange={this.handleChange}
            inputProps={{ maxLength: 1000 }}
          />
        </FormControl>
      </>
    );
  }

  enhancementForm() {
    return (
      <>
        <FormControl className="margin-bottom-20" fullWidth>
          <FormLabel>
            3. What Page(s) would you like to enhance?
            {' '}
            <span className="mandatory"> *</span>
          </FormLabel>
          {/* <FormControlLabel
            control={
              <Checkbox checked={this.state.currentStateOverviewEnhance} onChange={this.handleChange} name="currentStateOverviewEnhance" />
            }
            label="Current State Overview"
          />
          <FormControlLabel
            control={
              <Checkbox checked={this.state.growthEnhance} onChange={this.handleChange} name="growthEnhance" />
            }
            label="Growth"
          />
          <FormControlLabel
            control={
              <Checkbox checked={this.state.marginExpansionEnhance} onChange={this.handleChange} name="marginExpansionEnhance" />
            }
            label="Margin Expansion"
          />
          <FormControlLabel
            control={
              <Checkbox checked={this.state.dlcPrioritiesEnhance} onChange={this.handleChange} name="dlcPrioritiesEnhance" />
            }
            label="DLC Priorities"
          />
          <FormControlLabel
            control={
              <Checkbox checked={this.state.commitmentsToMetaEnhance} onChange={this.handleChange} name="commitmentsToMetaEnhance" />
            }
            label="Commitments to Meta"
          />
          <FormControlLabel
            control={
              <Checkbox checked={this.state.valueToMetaEnhance} onChange={this.handleChange} name="valueToMetaEnhance" />
            }
            label="Value to Meta"
          />
          <FormControlLabel
            control={
              <Checkbox checked={this.state.valueToAcnEnhance} onChange={this.handleChange} name="valueToAcnEnhance" />
            }
            label="Value to ACN"
          />
          <FormControlLabel
            control={
              <Checkbox checked={this.state.valueToOurPeopleEnhance} onChange={this.handleChange} name="valueToOurPeopleEnhance" />
            }
            label="Value to our People"
          /> */}
          <FormControlLabel
            control={
              <Checkbox checked={this.state.salesExecutionEnhance} onChange={this.handleChange} name="salesExecutionEnhance" />
            }
            label="Sales Execution"
          />
          {/* <FormControlLabel
            control={
              <Checkbox checked={this.state.commercialExecutionEnhance} onChange={this.handleChange} name="commercialExecutionEnhance" />
            }
            label="Commercial Execution"
          />
          <FormControlLabel
            control={
              <Checkbox checked={this.state.additionalPrioritiesEnhance} onChange={this.handleChange} name="additionalPrioritiesEnhance" />
            }
            label="Additional Priorities"
          />
          <FormControlLabel
            control={
              <Checkbox checked={this.state.reportBuilderEnhance} onChange={this.handleChange} name="reportBuilderEnhance" />
            }
            label="Report Builder"
          /> */}
          <FormControlLabel
            control={(
              <>
                <Checkbox checked={this.state.otherEnhance} onChange={this.handleChange} name="otherEnhance" />
                <TextField
                  name="otherEnhanceText"
                  variant="standard"
                  placeholder="Other"
                  value={this.state.otherEnhanceText}
                  onChange={this.handleChange}
                  inputProps={{ maxLength: 1000 }}
                />
              </>
              )}
            label=""
          />
        </FormControl>
        <FormControl className="margin-bottom-20" variant="standard" fullWidth>
          <FormLabel>
            4. Please describe what additional functionality you would like to see.
            {' '}
            <span className="mandatory"> *</span>
          </FormLabel>
          <OutlinedInput
            name="enhanceDescription"
            variant="outlined"
            value={this.state.enhanceDescription}
            onChange={this.handleChange}
            inputProps={{ maxLength: 1000 }}
          />
        </FormControl>
        <FormControl className="margin-bottom-20" variant="standard" fullWidth>
          <FormLabel>
            5. What is the business impact of this enhancement?
            {' '}
            <span className="mandatory"> *</span>
          </FormLabel>
          <OutlinedInput
            name="enhanceImpact"
            variant="outlined"
            value={this.state.enhanceImpact}
            onChange={this.handleChange}
            inputProps={{ maxLength: 1000 }}
          />
        </FormControl>
        <FormControl className="margin-bottom-20" fullWidth>
          <FormLabel>
            6. When do you need this enhancement by?
            {' '}
            <span className="mandatory"> *</span>
          </FormLabel>
          <RadioGroup value={this.state.timeForEnhance} onChange={this.handleChange} name="timeForEnhance">
            <FormControlLabel
              value="I need this as soon as possible and have no workaround"
              control={(
                <Radio size="small" />)}
              label="I need this as soon as possible and have no workaround"
            />
            <FormControlLabel
              value="I need this by a specific date"
              control={(<Radio size="small" />)}
              label="I need this by a specific date"
            />
            <FormControlLabel
              value="I need this but have no specific time or urgency"
              control={(<Radio size="small" />)}
              label="I need this but have no specific time or urgency"
            />
            <FormControlLabel
              value="This is a nice to have"
              control={(<Radio size="small" />)}
              label="This is a nice to have"
            />
          </RadioGroup>
        </FormControl>
        <FormControl className="margin-bottom-20" variant="standard" fullWidth>
          <FormLabel>7. (Optional) Please include any additional relevant information.</FormLabel>
          <OutlinedInput
            name="enhanceAdditionalInfo"
            variant="outlined"
            value={this.state.enhanceAdditionalInfo}
            onChange={this.handleChange}
            inputProps={{ maxLength: 1000 }}
          />
        </FormControl>

      </>
    );
  }

  render() {
    return (
      <div>
        <IntroductionBanner />
        <Snackbar
          open={this.state.snackbar}
          autoHideDuration={5000}
          onClose={() => { this.setState({ snackbar: false }); }}
          message="Submitted successfully"
        />
        <Grid container id="contactUs">
          <Grid item xs={12} className="disclaimer">
            <p>
              Please complete this form with your new request for the Account Performance Insights. After submitting your request, you will receive
              communications with next steps and the team will request any additional details necessary. Requests will be completed based on date submitted,
              priority, and level of effort. Please include as much detail as possible as our initial understanding of the requirement will help expedite the
              process. We appreciate your input!
              <br />
              <br />
              Meta Reporting and Analytics Team
            </p>
          </Grid>

          <Grid item xs={12} className="margin-bottom-20">
            {this.state.agreementRatio ? (
              <>
                <FormControl fullWidth className="margin-bottom-20">
                  <FormLabel>
                    2. What is the nature of your request?
                    {' '}
                    <span className="mandatory">*</span>
                  </FormLabel>
                  <RadioGroup value={this.state.natureRequest} onChange={this.handleChange} name="natureRequest">
                    <FormControlLabel
                      value="generate information"
                      control={(
                        <Radio size="small" />)}
                      label="General Information"
                    />
                    <FormControlLabel
                      value="bug fix"
                      control={(<Radio size="small" />)}
                      label="Bug Fix"
                    />
                    <FormControlLabel
                      value="enhacement request"
                      control={(<Radio size="small" />)}
                      label="Enhancement Request"
                    />
                  </RadioGroup>
                </FormControl>

                {(this.state.natureRequest === 'generate information') ? this.generalInputForm() : ''}
                {(this.state.natureRequest === 'bug fix') ? this.bugInputForm() : ''}
                {(this.state.natureRequest === 'enhacement request') ? this.enhancementForm() : ''}
              </>
            ) : (
              <FormControl>
                <FormLabel>
                  1. Accenture will process your personal data through this poll in accordance with our Global Data Privacy Statement
                  <a className="fontsize-14" href="https://in.accenture.com/protectingaccenture/data-security/5422-2/" target="_blank" rel="noreferrer"> https://in.accenture.com/protectingaccenture/data-security/5422-2/</a>
                  {' '}
                  or for external users
                  {' '}
                  <a className="fontsize-14" href="https://www.accenture.com/us-en/about/privacy-policy*" target="_blank" rel="noreferrer"> https://www.accenture.com/us-en/about/privacy-policy</a>
                  <span className="mandatory"> *</span>
                </FormLabel>
                <RadioGroup value={this.state.agreementRatio} onChange={this.handleChange} name="agreementRatio">
                  <FormControlLabel
                    value
                    control={(
                      <Radio size="small" />)}
                    label="I Agree"
                  />
                  <FormControlLabel
                    value={false}
                    control={(<Radio size="small" />)}
                    label="I Disagree"
                  />
                </RadioGroup>
              </FormControl>
            ) }

          </Grid>

          <Grid item xs={12}>
            <p className="fontsize-12 margin-bottom-20">
              Note: Fields marked with an
              {' '}
              <span className="mandatory">*</span>
              {' '}
              are mandatory
            </p>
          </Grid>

          <Grid item xs={12} className="flex flex-center relative">
            <Button className={`button-purple ${!this.state.allRequirementsButton ? 'button-disabled' : ''}`} disabled={!this.state.allRequirementsButton} variant="contained" onClick={() => { this.submitForm(); }}>Submit</Button>
            {this.state.loadingSubmit && (
              <CircularProgress
                size={24}
                sx={{
                  color: '#a100ff',
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  marginTop: '-12px',
                  marginLeft: '-12px',
                }}
              />
            )}
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default ContactUs;
