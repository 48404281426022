import React from 'react';
import {
  Grid, Card, CardContent, Skeleton,
} from '@mui/material';

import '../../App.css';

class ValueToAcn extends React.Component {
  render() {
    return (
      <Grid container spacing={2} sx={{ marginTop: '20px' }}>
        <Grid item xs={4}>
          <Card raised={false} sx={{ minWidth: 275 }}>
            <CardContent>
              <h5>Description</h5>
              <p />
              <h5>Purpose</h5>
              <p />
              <h5>Scope</h5>
              <p />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={4}>
          <Card raised={false} sx={{ minWidth: 275 }}>
            <CardContent>
              <h5>Are we managing and mitigating risk?</h5>
              <Skeleton />
              <Skeleton animation="wave" />
              <Skeleton animation={false} />
              <Skeleton />
              <Skeleton animation="wave" />
              <Skeleton animation={false} />
              <Skeleton />
              <Skeleton animation="wave" />
              <Skeleton animation={false} />
              <Skeleton />
              <br />
              <br />
              <p>TBD</p>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={4}>
          <Card raised={false} sx={{ minWidth: 275 }}>
            <CardContent>
              <h5>Are we meeting Accenture targets?</h5>
              <Skeleton />
              <Skeleton animation="wave" />
              <Skeleton animation={false} />
              <Skeleton />
              <Skeleton animation="wave" />
              <Skeleton animation={false} />
              <Skeleton />
              <Skeleton animation="wave" />
              <Skeleton animation={false} />
              <Skeleton />
              <br />
              <br />
              <br />
              <p />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={4}>
          <Card raised={false} sx={{ minWidth: 275 }}>
            <CardContent>
              <h5>Are we meeting our forecasts?</h5>
              <Skeleton />
              <Skeleton animation="wave" />
              <Skeleton animation={false} />
              <Skeleton />
              <Skeleton animation="wave" />
              <Skeleton animation={false} />
              <Skeleton />
              <Skeleton animation="wave" />
              <Skeleton animation={false} />
              <Skeleton />
              <br />
              <br />
              <br />
              <p />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={4}>
          <Card raised={false} sx={{ minWidth: 275 }}>
            <CardContent>
              <h5>Are we invoicing and getting paid in a timely fashion?</h5>
              <Skeleton />
              <Skeleton animation="wave" />
              <Skeleton animation={false} />
              <Skeleton />
              <Skeleton animation="wave" />
              <Skeleton animation={false} />
              <Skeleton />
              <Skeleton animation="wave" />
              <Skeleton animation={false} />
              <Skeleton />
              <br />
              <br />
              <br />
              <p />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    );
  }
}

export default ValueToAcn;
