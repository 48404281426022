import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid, Divider, Accordion, AccordionSummary, AccordionDetails,
} from '@mui/material';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { ExpandMore, Circle, Close } from '@mui/icons-material';

import Moment from 'react-moment';

import * as constantImports from '../../constants';

import '../../App.css';

class Notifications extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };

    this.handleAccordionChange = this.handleAccordionChange.bind(this);
  }

  async handleAccordionChange(index, option) {
    if (!option.read) {
      const notificationsWrapper = [...this.props.notifications];
      const { preferredEmail } = this.props;
      notificationsWrapper[index].read = true;
      this.props.changeNotifications(notificationsWrapper);

      const instance = axios.create({
        headers: constantImports.amazonHeader(this.state.idToken),
      });
      await instance.post(`${constantImports.AMAZON_API_BASE_VALUE}/notifications`, { id: option.id, preferredEmail }).then((response) => {
        console.log(response.data);
      });
    }
  }

  render() {
    return (
      <div>
        <Grid container id="notifications" className="padding_0830">
          <Grid item xs={11}>
            <h1>My Notifications</h1>
          </Grid>
          <Grid item xs={1} className="text-right">
            <Link to="/">
              {/* <Link to={this.props.path}> */}
              <Close className="cursor-pointer" />
            </Link>
          </Grid>
          <Grid item xs={12}>
            <Divider className="colorHr" />
          </Grid>
          <Grid container className="padding_3008">
            <Grid item xs={12}>
              {this.props.notifications.map((option, index) => (
                <Accordion onClick={() => { this.handleAccordionChange(index, option); }}>
                  <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Grid container>
                      <Grid item xs={12} className="flex" sx={{ alignItems: 'center' }}>
                        <Circle className={`circle ${option.read ? 'openedCircle' : ''}`} sx={{ fontSize: 16, marginRight: '10px' }} />
                        <h6 className={`${option.read ? 'openedTitle' : ''}`}>{option.title}</h6>
                      </Grid>
                      <Grid item xs={12}>
                        <Moment className="subtitle" element="h6" format="DD MMMM, YYYY" date={option.date} />
                      </Grid>
                    </Grid>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid Container>
                      <Grid item xs={12}>
                        <Divider className="colorHr" />
                      </Grid>
                      <Grid item xs={12}>
                        <p>{option.description}</p>
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}

Notifications.propTypes = {
  notifications: PropTypes.arrayOf(PropTypes.object).isRequired,
  changeNotifications: PropTypes.func.isRequired,
  preferredEmail: PropTypes.string.isRequired,
  // path: PropTypes.string.isRequired,
};

export default Notifications;
