import React from 'react';
import {
  Grid, Card, CardContent, Skeleton,
} from '@mui/material';

import '../../App.css';

class Growth extends React.Component {
  render() {
    return (
      <Grid container spacing={2} sx={{ marginTop: '20px' }}>
        <Grid item xs={4}>
          <Card raised={false} sx={{ minWidth: 275 }}>
            <CardContent>
              <h5>Description</h5>
              <p> A single page that provides high level indicators and insights on revenue growth and revenue drivers.</p>
              <h5>Purpose</h5>
              <p>Provide leadership with all the key indicators for measuring current and historical revenue with the goal of identifying opportunities for increasing revenue growth.</p>
              <h5>Scope</h5>
              <p>Account-wide with ability to see detail at the portfolio level down to the program level and by location and service group.</p>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={4}>
          <Card raised={false} sx={{ minWidth: 275 }}>
            <CardContent>
              <h5>How fast are we growing revenue?  Where has growth slowed/accelerated?</h5>
              <Skeleton />
              <Skeleton animation="wave" />
              <Skeleton animation={false} />
              <Skeleton />
              <Skeleton animation="wave" />
              <Skeleton animation={false} />
              <Skeleton />
              <Skeleton animation="wave" />
              <Skeleton animation={false} />
              <Skeleton />
              <br />
              <br />
              <p>MOM,QOQ,YOY revenue growth rates</p>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={4}>
          <Card raised={false} sx={{ minWidth: 275 }}>
            <CardContent>
              <h5>Are we maximizing revenue on current contracts?</h5>
              <Skeleton />
              <Skeleton animation="wave" />
              <Skeleton animation={false} />
              <Skeleton />
              <Skeleton animation="wave" />
              <Skeleton animation={false} />
              <Skeleton />
              <Skeleton animation="wave" />
              <Skeleton animation={false} />
              <Skeleton />
              <br />
              <br />
              <br />
              <p>Opportunity Loss on Max Bill Contracts</p>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card raised={false} sx={{ minWidth: 275 }}>
            <CardContent>
              <h5>Are we expanding our revenue opportunities at META?</h5>
              <Skeleton />
              <Skeleton animation="wave" />
              <Skeleton animation={false} />
              <Skeleton />
              <Skeleton animation="wave" />
              <Skeleton animation={false} />
              <Skeleton />
              <Skeleton animation="wave" />
              <Skeleton animation={false} />
              <Skeleton />
              <br />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    );
  }
}

export default Growth;
