import React from 'react';
import { Grid } from '@mui/material';

import * as constants from '../../constants';

import '../../App.css';
import '../../styles/homePage.css';
import 'bootstrap/dist/css/bootstrap.min.css';

class IntroductionBanner extends React.Component {
  mainBody() {
    return (
      <div>
        <Grid container id="principalSection" className="flex text-left">
          <Grid item xs={12} md={8}>
            <h1>{constants.homeText.title}</h1>
            <h4>{constants.homeText.subtitle}</h4>
          </Grid>
        </Grid>
      </div>
    );
  }

  render() {
    return (
      <div>
        {this.mainBody()}
      </div>
    );
  }
}

export default IntroductionBanner;
