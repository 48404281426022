export const navOptions = [
    {
      name: 'HOME',
      url: '/',
    },
    {
      name: 'CONTACT US',
      url: '/contact-us',
    },
  ];
  
  export const homeText = {
    title: 'Account Performance Insights',
    subtitle: 'Deliver actionable insights based on in-depth financial and operating performance analysis.',
    whatIsTextTitle: 'What is Account Insights',
    whatIsTextSubtitle: 'The Account Performance Insights is a financial and non-financial analysis tool that helps to'
                        + ' better understand Meta account performance and identify value creation opportunities.',
  };
  export const homeOptions = [
    {
      name: 'Details of Sources',
      tabs: [
        {
          tabName: 'DATA SOURCE',
          subTabs: [
            {
              name: 'SAP',
              option1: '/',
              option2: '/',
              subtabs: [],
            },
            {
              name: 'CBP',
              option1: '/',
              option2: '/',
              subtabs: [],
            },
            {
              name: 'MMT',
              option1: '/',
              option2: '/',
              subtabs: [],
            },
            {
              name: 'MMS',
              option1: '/',
              option2: '/',
              subtabs: [],
            },
            {
              name: 'MME',
              option1: '/',
              option2: '/',
              subtabs: [],
            },
            {
              name: 'MMC',
              option1: '/',
              option2: '/',
              subtabs: [],
            },
            {
              name: 'Workday',
              option1: '/',
              option2: '/',
              subtabs: [],
            },
            {
              name: 'myLearning',
              option1: '/',
              option2: '/',
              subtabs: [],
            },
          ],
        },
      ],
    },
  ];
  export const clientContactsSponsors = [
    {
      img: 'imgs/contacts/neerajdvadhan.png',
      name: 'Neeraj Vadhan',
      text: 'Client Account Lead',
      mailto: 'mailto:neeraj.d.vadhan@accenture.com',
    },
    {
      img: 'imgs/contacts/jamesdcrinklaw.png',
      name: 'James D. Crinklaw',
      text: 'Commercial Director',
      mailto: 'mailto:james.d.crinklaw@accenture.com',
    },
    {
      img: 'imgs/contacts/kranthichiluka.png',
      name: 'Kranthi Chiluka',
      text: 'Applied Intelligence Lead',
      mailto: 'mailto:kranthi.chiluka@accenture.com',
    },
  ];
  export const clientContacts = [
    {
      img: 'imgs/contacts/andrewkuo.png',
      name: 'Andy Kuo',
      text: 'R&A Program Lead',
      mailto: 'mailto:andrew.kuo@accenture.com',
    },
    {
      img: 'imgs/contacts/reginaldhamilton.png',
      name: 'Reggie Hamilton',
      text: 'R&A Technical Lead',
      mailto: 'mailto:reginald.hamilton@accenture.com',
    },
    {
      img: 'imgs/contacts/seannorris.png',
      name: 'Sean Norris',
      text: 'API Product Manager',
      mailto: 'mailto:sean.norris@accenture.com',
    },
  ];
  
  export const menuTabs = [
    {
      name: 'Overview',
      subname: 'Current State',
    },
    {
      name: 'Growth',
      subname: 'Are we growing/sustaining our business?​',
    },
    {
      name: 'Margin Expansion',
      subname: 'Are we improving/maintaining our profitability?​',
    },
    {
      name: 'DLC Priorities',
      subname: 'Are we advancing Accenture’s DLC Priorities?​',
    },
    {
      name: 'Commitments to Meta',
      subname: 'Are we meeting our contractual commitments?​',
    },
    {
      name: 'Value to Meta',
      subname: 'Are we increasing our value to META?​',
    },
    {
      name: 'Value to ACN',
      subname: 'Are we increasing our value to ACN?​',
    },
    {
      name: 'Value to our people',
      subname: 'Are we managing our people effectively? ​',
    },
    {
      name: 'Sales Execution',
      subname: 'Are we selling effectively?​',
    },
    {
      name: 'Commercial Execution',
      subname: 'Are we executing commercial activities well?​',
    },
  ];
  
  //
  export const AMAZON_API_BASE_VALUE = 'https://3fzuwcbvoc.execute-api.us-east-1.amazonaws.com/prod';
  // if (process.env.REACT_APP_ENVIROMENT === 'production') {
  //   AMAZON_API_BASE_VALUE = 'https://3fzuwcbvoc.execute-api.us-east-1.amazonaws.com/prod';
  // } else {
  //   AMAZON_API_BASE_VALUE = 'https://u0o8cj43t5.execute-api.us-east-1.amazonaws.com/prod';
  // }

  export function amazonHeader(token) {
    const AMAZON_API_HEADER_OBJECT = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      authorizationToken: token,
    };
  
    return AMAZON_API_HEADER_OBJECT;
  }
  