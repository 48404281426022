/* eslint-disable react/no-unused-state */
import React from 'react';
import { Container } from 'react-bootstrap';
import {
  Button,
} from '@mui/material';

import { Chat, Close } from '@mui/icons-material';

import { isExpired, decodeToken } from 'react-jwt';
import {
  HashRouter as Router,
  // BrowserRouter as Router,
  Routes,
  Route,
} from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie';

import HomePage from './components/homePage';
import InformationPage from './components/informationPage';
import Header from './components/commonComponents/header';
import ContactUs from './components/contactUs';
import Feedback from './components/feedback';
import Notifications from './components/commonComponents/notifications';
import Footer from './components/commonComponents/footer';

import * as apisConst from './apis.const';
import * as UtilsFunction from './utils';
import * as constantImports from './constants';

import './App.css';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      notifications: [],
      notificationsLength: 0,
      openNotifications: false,
      feedbackButton: true,
      feedbackModal: false,

      path: window.location.pathname,

      userName: null,
      preferredEmail: '',
      userRoles: null,
      expirationDate: null,
      idToken: null,

      filters: {
        fiscal_month: [],
        fiscal_quarter: [],
        market: [],
        portfolio: [],
        service: [],
      },

      showModal: false,
      didPageLoad: true,
      isDateExpired: true,
      isPageHide: true,

      authorizedRoles: apisConst.AUTH_ROLES,
    };

    this.openCloseNotifications = this.openCloseNotifications.bind(this);
    this.closeFeedbackModal = this.closeFeedbackModal.bind(this);
    this.openFeedbackModal = this.openFeedbackModal.bind(this);
    this.updatePath = this.updatePath.bind(this);
    this.changeNotifications = this.changeNotifications.bind(this);
    this.getNotifications = this.getNotifications.bind(this);
  }

  componentDidMount() {
    this.getToken();
  }

  handleClose() {
    Cookies.remove('expiration_date');
    Cookies.remove('preferred_email');
    Cookies.remove('user_roles');
    Cookies.remove('user_name');
    Cookies.remove('id_token');
    Cookies.remove('refreshed');

    UtilsFunction.authenticateWithCognito(process.env.REACT_APP_ENVIROMENT);
  }

  async getToken() {
    if (Cookies.get('id_token') !== '' && Cookies.get('id_token') != null) {
      if (Cookies.get('refreshed')) {
        let expirationDate = String(Cookies.get('expiration_date')).split(',');
        expirationDate = new Date(expirationDate * 1000);
        const isMyTokenExpired = isExpired(Cookies.get('id_token'));

        await this.setState({
          userName: Cookies.get('user_name'),
          preferredEmail: Cookies.get('preferred_email'),
          userRoles: String(Cookies.get('user_roles')).split(','),
          expirationDate,
          isDateExpired: isMyTokenExpired,
          idToken: Cookies.get('id_token'),
        });

        if (isMyTokenExpired) {
          this.handleClose();
        } else {
          this.checkAuth(isMyTokenExpired, Cookies.get('id_token'));
        }
      } else {
        this.handleClose();
      }
    } else {
      const results = new RegExp('[?&#]id_token=([^&#]*)').exec(window.location.href);
      let userName = null;
      let roles = null;
      let preferredEmailCamel = null;
      let expirationDate = null;

      if (results) {
        const [idToken] = results;
        const myDecodedToken = decodeToken(idToken);
        const token = idToken.split("=");
        const isMyTokenExpired = isExpired(idToken);

        if (idToken && idToken.indexOf('.') > 0) {
          const signature = (idToken.split('.')[2]) ? idToken.split('.')[2] : [];
          if (signature.length <= 0) {
            this.handleClose();
            return;
          }

          userName = myDecodedToken.name;
          roles = myDecodedToken.roles;
          preferredEmailCamel = myDecodedToken.preferred_username;
          expirationDate = myDecodedToken.exp;
        }

        Cookies.set('user_name', userName, { secure: true });
        Cookies.set('user_roles', roles, { secure: true });
        Cookies.set('preferred_email', preferredEmailCamel, { secure: true });
        Cookies.set('expiration_date', expirationDate, { secure: true });
        Cookies.set('id_token', token[1], { secure: true });
        Cookies.set('refreshed', false, { secure: true });
        await this.setState({
          userName,
          preferredEmail: preferredEmailCamel,
          userRoles: String(roles).split(','),
          expirationDate: myDecodedToken.exp,
          isDateExpired: isMyTokenExpired,
          idToken,
        });
        this.checkAuth(isMyTokenExpired, idToken);
      } else {
        this.handleClose();
      }
    }
  }

  getNotifications(preferredEmail) {
    const instance = axios.create({
      headers: constantImports.amazonHeader(this.state.idToken),
    });
    instance.get(`${constantImports.AMAZON_API_BASE_VALUE}/notifications`, { params: { userId: preferredEmail } }).then((response) => {
      const notifications = [];
      let notificationsLength = 0;
      response.data.forEach((element) => {
        const wrapper = {
          date: element.date,
          description: element.description,
          id: element.id,
          import_date: element.import_date,
          read: ((element.read === 'true')),
          title: element.title,
        };

        if (!wrapper.read) {
          notificationsLength += 1;
        }

        notifications.push(wrapper);
      });

      this.setState({
        notifications,
        notificationsLength,
      });
    });
  }

  getFilters() {
    const instance = axios.create({
      headers: constantImports.amazonHeader(this.state.idToken),
    });

    instance.get(`${constantImports.AMAZON_API_BASE_VALUE}/filters`).then((response) => {
      this.setState({
        filters: response.data,
      });
    });
  }

  openCloseNotifications() {
    let { openNotifications } = this.state;
    openNotifications = !openNotifications;
    this.setState({
      openNotifications,
    });
  }

  changeNotifications(notifications) {
    let notificationsLength = 0;

    notifications.forEach((element) => {
      if (!element.read) {
        notificationsLength += 1;
      }
    });

    this.setState({
      notifications,
      notificationsLength,
    });
  }

  checkAuth(exp) {
    if (this.state.userRoles.length > 0) {
      for (let i = 0; i < this.state.userRoles.length; i += 1) {
        let isFound = this.state.authorizedRoles.some((element) => element === this.state.userRoles[i]);
        if (this.state.authorizedRoles[0] === 'FULL_ACCESS') {
          isFound = true;
        }
        if (isFound && !exp) {
          if (window.location.hash.length > 0) {
            Cookies.set('refreshed', true, { secure: true });
            window.location = window.location.origin;
            return;
          }

          this.getNotifications(this.state.preferredEmail);
          this.getFilters();

          this.setState({ isPageHide: false });

          const { expirationDate } = this.state;
          const today = new Date();
          const milliseconds = Math.abs(expirationDate - today);

          setTimeout(() => {
            this.setState({
              showModal: true,
              didPageLoad: false,
              isDateExpired: true,
              isPageHide: true,
            });
          }, milliseconds);

          if (Cookies.get('refreshed')) {
            Cookies.remove('id_token');
            Cookies.remove('expiration_date');
            Cookies.remove('user_roles');
            Cookies.remove('user_name');
            Cookies.remove('user_email');
            Cookies.remove('refreshed');
            Cookies.remove('preferred_email');
          }

          return;
        }
        if (i === (this.state.userRoles.length - 1)) {
          this.setState({ didPageLoad: false });
        }
      }
    } else {
      this.setState({ didPageLoad: false });
    }
  }

  updatePath(path) {
    this.setState({
      path,
    });
  }

  closeFeedbackModal() {
    this.setState({
      feedbackModal: false,
    });
  }

  openFeedbackModal() {
    this.setState({
      feedbackModal: true,
    });
  }

  render() {
    return (
      <Container className="main-container padding_0">
        <Router>
          <Header notificationsLength={this.state.notificationsLength} updatePath={this.updatePath} />
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/analysis" element={<InformationPage idToken={this.state.idToken} filters={this.state.filters} />} />
            <Route path="/contact-us" element={<ContactUs idToken={this.state.idToken} />} />
            <Route path="/notifications" element={<Notifications preferredEmail={this.state.preferredEmail} notifications={this.state.notifications} path={this.state.path} changeNotifications={this.changeNotifications} />} />
          </Routes>
          <Footer openFeedbackModal={this.openFeedbackModal} />

          {this.state.feedbackButton ? (
            <Button className="button-feedback" variant="contained">
              <Chat sx={{ fontSize: 20 }} onClick={() => { this.setState({ feedbackModal: true }); }} />
              <span onClick={() => { this.setState({ feedbackModal: true }); }}>Feedback</span>
              <Close onClick={() => { this.setState({ feedbackButton: false }); }} sx={{ fontSize: 16 }} />
            </Button>
          ) : ''}

          {this.state.feedbackModal ? <Feedback idToken={this.state.idToken} closeModal={this.closeFeedbackModal} /> : ''}
        </Router>
      </Container>
    );
  }
}

export default App;
