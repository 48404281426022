import React from 'react';
import {
  Grid, Card, CardContent,
} from '@mui/material';

import '../../App.css';

class ValueToOurPeople extends React.Component {
  render() {
    return (
      <Grid container spacing={2} sx={{ marginTop: '20px' }}>
        <Grid item xs={5}>
          <Card raised={false} sx={{ minWidth: 275 }}>
            <CardContent>
              <h5>Description</h5>
              <p />
              <h5>Purpose</h5>
              <p />
              <h5>Scope</h5>
              <p />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={7}>
          <Card raised={false} sx={{ minWidth: 275 }}>
            <CardContent>
              <h5>WHAT IS OUR CURRENT AND HISTORIC HEADCOUNT?</h5>
              <img src="imgs/image3.jpg" className="image-width-90" alt="image3" />
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={6}>
          <Card raised={false} sx={{ minWidth: 275 }}>
            <CardContent>
              <h5>ARE WE ABLE TO RETAIN OUR TEAM MEMBERS?</h5>
              <img src="imgs/image2.jpg" className="image-width-90" alt="image2" />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={6}>
          <Card raised={false} sx={{ minWidth: 275 }}>
            <CardContent>
              <h5>ARE WE MEETING CHARGEABILITY TARGETS?</h5>
              <img src="imgs/image4.jpg" className="image-width-90" alt="image4" />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={4}>
          <Card raised={false} sx={{ minWidth: 275 }}>
            <CardContent>
              <h5>ARE WE BUILDING AND SUPPORTING DIVERSITY?</h5>
              <img src="imgs/image5.jpg" className="image-width-90" alt="image5" />

            </CardContent>
          </Card>
        </Grid>
      </Grid>
    );
  }
}

export default ValueToOurPeople;
