import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Button, Divider } from '@mui/material';

import * as constants from '../../constants';

import MyReport from '../commonComponents/myReport';
import MyWatchlist from '../commonComponents/myWatchlist';

import '../../App.css';

class AccountAnalysis extends React.Component {
  render() {
    return (
      <>
        <Grid container sx={{ marginTop: '50px' }}>
          {constants.menuTabs.map((tab) => (
            <Grid item xs={6} key={tab.name} className="flex flex-center">
              <Button variant="contained" sx={{ width: '90% !important' }} className="flex flex-column flex-center button-purple margin-bottom-40 width_90" onClick={() => { this.props.analysisButtons(tab.name, tab.subname); }}>
                {tab.name}
                <span>{tab.subname}</span>
              </Button>
            </Grid>
          ))}
        </Grid>

        <Grid container sx={{ marginTop: '50px' }}>
          <Grid item xs={12}>
            <Divider className="colorHr" />
          </Grid>
        </Grid>

        <Grid container id="myDashboard">
          <h2>My Dashboard</h2>
          <Grid item xs={12} className="margin-bottom-40">
            <MyReport />
          </Grid>
          <Grid item xs={12}>
            <MyWatchlist />
          </Grid>
        </Grid>
      </>
    );
  }
}

AccountAnalysis.propTypes = {
  analysisButtons: PropTypes.func.isRequired,
};

export default AccountAnalysis;
