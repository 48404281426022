import React from 'react';
import {
  Grid, Card, CardContent, Tabs, Tab, Box, Typography, Tooltip, Button
} from '@mui/material';
import { ArrowDropUp, ArrowDropDown} from '@mui/icons-material';

import PropTypes from 'prop-types';
import 'chart.js/auto';
import { Bar, Line, getElementAtEvent } from 'react-chartjs-2';
import GaugeChart from 'react-gauge-chart';

import '../../App.css';
import '../../styles/informationPage.css';

import * as UtilsFunction from '../../utils';

export const chartStyle = {
  height: '200px',
  maxHeight: '160px'
};

function a11yProps(index) {
  return {
    id: index,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ paddingTop: '20px' }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

class SalesExecution extends React.Component {
  constructor(props) {
    super(props);
    this.selectorRef = React.createRef(null);
    this.state = {
      tabSelected: 0,
      testData: [
        {
          backgroundColor: "rgb(255, 99, 132)",
          borderColor: "rgb(255, 99, 132)",
          data: [-84.395],
          label: "North America"
        },
        {
          backgroundColor: "rgb(255, 159, 64)",
          borderColor: "rgb(255, 159, 64)",
          data: [-63.582],
          label: "Europe"
        },
        {
          backgroundColor: "rgb(75, 192, 192)",
          borderColor: "rgb(75, 192, 192)",
          data: [40.622],
          label: "Growth Markets"
        },
      ],
      testData1: [
        {
          backgroundColor: "rgb(255, 99, 132)",
          borderColor: "rgb(255, 99, 132)",
          data: [-84.395],
          label: "North America"
        },
        {
          backgroundColor: "rgb(255, 159, 64)",
          borderColor: "rgb(255, 159, 64)",
          data: [-63.582],
          label: "Europe"
        },
        {
          backgroundColor: "rgb(75, 192, 192)",
          borderColor: "rgb(75, 192, 192)",
          data: [40.622],
          label: "Growth Markets"
        },
      ],
      testDataStacked: [
        {
          backgroundColor: "rgb(255, 99, 132)",
          borderColor: "rgb(255, 99, 132)",
          data: [81, 21, 112],
        },
        {
          backgroundColor: "rgb(255, 159, 64)",
          borderColor: "rgb(255, 159, 64)",
          data: [0, 4, 50],
        },
      ],
      testColumn: ['Q1 FY23'],
      testColumnStacked: ['North America', 'Europe', 'Growth Markets']
    };

    this.gaugeColor = this.gaugeColor.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleClickBack = this.handleClickBack.bind(this);
  }

  gaugePercentage(percentage) {
    if ((percentage) > 100) {
      return 1;
    }
    return percentage;
  }

  gaugeColor(percentage, name, type) {
    let colors = [];
    if ((percentage) < 80) {
      colors = ['#70091a', '#A10F27', '#A10F2760'];
    } else if ((percentage) >= 80 && (percentage) < 100) {
      colors = ['#d2a700', '#F1C000', '#F1C00060'];
    } else {
      colors = ['#094804', '#357330', '#35733060'];
    }

    if (this.props.portfolio !== '' && type === 'portfolio') {
      if (this.props.portfolio !== name) {
        colors = ['#7f7f7f', '#c1c1c1'];
      }
    }
    if (this.props.market !== '' && type === 'market') {
      if (this.props.market !== name) {
        colors = ['#7f7f7f', '#c1c1c1'];
      }
    }
    if (this.props.service !== '' && type === 'service') {
      if (this.props.service !== name) {
        colors = ['#7f7f7f', '#c1c1c1'];
      }
    }
    return colors;
  }

  needleColor(percentage) {
    let color = '';
    if ((percentage) < 100) {
      color = '#464A4F';
    } else {
      color = '#FFFFFF';
    }
    return color;
  }

  calculateActualPercentage(total, actual) {
    const result = (actual * 100) / total;
    return result / 100; 
  }

  handleChange(e) {
    this.setState({
      tabSelected: parseInt(e.target.id)
    });
  }

  currentQuarter() {
    const d = new Date();
    const month = d.getMonth();
    let currentQ = '';

    if (month >= 8 && month <= 10) {
      currentQ = 'Q1'
    } else if (month === 11 || month === 0 || month === 1) {
      currentQ = 'Q2'
    } else if (month >= 2 && month <= 4) {
      currentQ = 'Q3'
    } else if (month >= 5 && month <= 7) {
      currentQ = 'Q4'
    }

    return currentQ;
  }

  // 
  handleClickInside = (e) => {
    let element = getElementAtEvent(this.selectorRef.current, e);
    if(element.length > 0) {
      let testData = []
      const { datasetIndex } = element[0];

      if(this.state.testData[datasetIndex].label === 'North America') {
        testData = [
          {
            backgroundColor: "rgb(255, 99, 132)",
            borderColor: "rgb(255, 99, 132)",
            data: [-81.108],
            label: "West"
          },
        ]
      } else if (this.state.testData[datasetIndex].label === 'Europe') {
        testData = [
          {
            backgroundColor: "rgb(255, 99, 132)",
            borderColor: "rgb(255, 99, 132)",
            data: [21.137],
            label: "UK, Ireland"
          },
          {
            backgroundColor: "rgb(255, 159, 64)",
            borderColor: "rgb(255, 159, 64)",
            data: [4.031],
            label: "ICEG"
          },
        ]
      } else if (this.state.testData[datasetIndex].label === 'Growth Markets') {
        testData = [
          {
            backgroundColor: "rgb(255, 99, 132)",
            borderColor: "rgb(255, 99, 132)",
            data: [112.509],
            label: "India"
          },
          {
            backgroundColor: "rgb(255, 159, 64)",
            borderColor: "rgb(255, 159, 64)",
            data: [-50.904],
            label: "SEA"
          },
        ]
      }

      this.setState({
        testData
      })

      console.log(this.state.testData[datasetIndex].label);
      // console.log(this.props.salesExecutionData.currentQoQGrowth[datasetIndex].label)
    }
  };

  handleClickBack() {
    this.setState({
      testData: this.state.testData1
    })
  }

  render() {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card raised={false} sx={{ minWidth: 275 }}>
            <CardContent sx={{ paddingBottom: '8px !important' }}>
              <Grid container>
                <Grid
                  item
                  xs={2}
                  className="flex"
                  sx={{
                    marginBottom: '10px', alignItems: 'center', justifyContent: 'flex-end', paddingRight: '10px', flexBasis: '11% !important'
                  }}
                >
                  <h5 className="text-right">Description</h5>
                </Grid>
                <Grid item xs={10} sx={{ maxWidth: '85% !important', flexBasis: '85% !important' }}>
                  <p style={{ marginBottom: '5px !important', lineHeight: '18px' }}>High-level metrics along with detailed analysis of account sales performance and growth.</p>
                </Grid>
                <Grid
                  item
                  xs={2}
                  className="flex"
                  sx={{
                    marginBottom: '10px', alignItems: 'center', justifyContent: 'flex-end', paddingRight: '10px', flexBasis: '11% !important'
                  }}
                >
                  <h5 className="text-right">Purpose</h5>
                </Grid>
                <Grid item xs={10} sx={{ maxWidth: '89% !important', flexBasis: '89% !important' }}>
                  <p style={{ marginBottom: '5px !important', lineHeight: '18px' }}>Provide leadership insights into our sales pipeline and processes to identify opportunities for increasing efficiency and promoting growth.</p>
                </Grid>
                <Grid
                  item
                  xs={2}
                  className="flex"
                  sx={{
                    marginBottom: '10px', alignItems: 'center', justifyContent: 'flex-end', paddingRight: '10px', flexBasis: '11% !important'
                  }}
                >
                  <h5 className="text-right">Scope</h5>
                </Grid>
                <Grid item xs={10} sx={{ maxWidth: '89% !important', flexBasis: '89% !important' }}>
                  <p style={{ marginBottom: '5px !important', lineHeight: '18px' }}>Historical and projected sales and forecast data with the ability to view data by Portfolio, Location, or Service Group. </p>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={this.state.tabSelected} onChange={this.handleChange} aria-label="Tabs" centered>
                <Tab sx={{ fontSize: '16px !important' }} label="Win Profitable Deals" {...a11yProps(0)} />
                <Tab sx={{ fontSize: '16px !important' }} label="Grow Quality Pipeline" {...a11yProps(1)} />
                <Tab sx={{ fontSize: '16px !important' }} label="Accelerate to Close" {...a11yProps(2)} />
              </Tabs>
            </Box>
            <TabPanel value={this.state.tabSelected} index={0}>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Card>
                      <CardContent>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <p className="subMenu-blue text-center">How are we progressing towards our sales targets?</p>
                          </Grid>

                          <Grid item xs={12}>
                            <Grid container>
                              <Grid item xs={12} className="text-center">
                                <h4 className="titleSection"><b>Portfolio</b></h4>
                              </Grid>
                              {this.props.salesExecutionData.planVsProjectedPortfolio.map((data) => (
                                <Grid item xs={4} className="text-center">
                                  <p><b>{data[0]}</b></p>
                                  <div onClick={() => { this.props.getData(this.props.fiscalQuarter, this.props.market, data[0], this.props.service, this.props.groupBy); }}>
                                    <GaugeChart
                                      id="gauge-chart1"
                                      cornerRadius={0}
                                      arcPadding={0}
                                      arcWidth={0.3}
                                      colors={this.gaugeColor(data[4], data[0], 'portfolio')}
                                      needleColor={this.needleColor(data[4])}
                                      needleBaseColor={this.needleColor(data[4])}
                                      textColor="#000"
                                      nrOfLevels={4}
                                      hideText
                                      arcsLength={[this.calculateActualPercentage(data[3], data[1]), ((data[4] / 100) - this.calculateActualPercentage(data[3], data[1])), (1 - (data[4] / 100))]}
                                      percent={this.gaugePercentage(data[4]) / 100}
                                      animate={false}
                                      style={chartStyle}
                                    />
                                  </div>
                                  <h4><b>{UtilsFunction.percentage(data[4] / 100)}</b></h4>
                                  <span style={{ marginBottom: '0px !important' }}>
                                    Plan:
                                    {' '}
                                    {UtilsFunction.currencyNoDecim(data[3])}
                                  </span>
                                  <br />
                                  <span style={{ marginBottom: '0px !important' }}>
                                    Projected Landing Position:
                                    {' '}
                                    {UtilsFunction.currencyNoDecim(data[2])}
                                  </span>
                                  <br />
                                  <span style={{ marginBottom: '0px !important' }}>
                                    Actuals:
                                    {' '}
                                    {UtilsFunction.currencyNoDecim(data[1])}
                                    <br />
                                    <br />
                                  </span>
                                </Grid>
                              ))}
                            </Grid>
                          </Grid>
                          <Grid item xs={12}>
                            <Grid container>
                              <Grid item xs={12} className="text-center">
                                <h4 className="titleSection"><b>Market</b></h4>
                              </Grid>
                              {this.props.salesExecutionData.planVsProjectedMarket.map((data) => (
                                <Grid item xs={4} className="text-center">
                                  <p><b>{data[0]}</b></p>
                                  <div onClick={() => { this.props.getData(this.props.fiscalQuarter, data[0], this.props.portfolio, this.props.service, this.props.groupBy); }}>
                                    <GaugeChart
                                      id="gauge-chart1"
                                      cornerRadius={0}
                                      arcPadding={0}
                                      arcWidth={0.3}
                                      colors={this.gaugeColor(data[4], data[0], 'market')}
                                      needleColor={this.needleColor(data[4])}
                                      needleBaseColor={this.needleColor(data[4])}
                                      textColor="#000"
                                      nrOfLevels={4}
                                      hideText
                                      arcsLength={[this.calculateActualPercentage(data[3], data[1]), ((data[4] / 100) - this.calculateActualPercentage(data[3], data[1])), (1 - (data[4] / 100))]}
                                      percent={this.gaugePercentage(data[4] / 100)}
                                      animate={false}
                                      style={chartStyle}
                                    />
                                  </div>
                                  <h4><b>{UtilsFunction.percentage(data[4] / 100)}</b></h4>
                                  <span style={{ marginBottom: '0px !important' }}>
                                    Plan:
                                    {' '}
                                    {UtilsFunction.currencyNoDecim(data[3])}
                                  </span>
                                  <br />
                                  <span style={{ marginBottom: '0px !important' }}>
                                    Projected Landing Position:
                                    {' '}
                                    {UtilsFunction.currencyNoDecim(data[2])}
                                  </span>
                                  <br />
                                  <span style={{ marginBottom: '0px !important' }}>
                                    Actuals
                                    {' '}
                                    {UtilsFunction.currencyNoDecim(data[1])}
                                    <br />
                                    <br />
                                  </span>
                                </Grid>
                              ))}
                            </Grid>
                          </Grid>
                          <Grid item xs={12}>
                            <Grid container>
                              <Grid item xs={12} className="text-center">
                                <h4 className="titleSection"><b>Service</b></h4>
                              </Grid>
                              {this.props.salesExecutionData.planVsProjectedService.map((data) => (
                                <Grid item xs={4} className="text-center">
                                  <p><b>{data[0]}</b></p>
                                  <div onClick={() => { this.props.getData(this.props.fiscalQuarter, this.props.market, this.props.portfolio, data[0], this.props.groupBy); }}>
                                    <GaugeChart
                                      id="gauge-chart1"
                                      cornerRadius={0}
                                      arcPadding={0}
                                      arcWidth={0.3}
                                      colors={this.gaugeColor(data[4], data[0], 'service')}
                                      needleColor={this.needleColor(data[4])}
                                      needleBaseColor={this.needleColor(data[4])}
                                      textColor="#000"
                                      nrOfLevels={4}
                                      hideText
                                      arcsLength={[this.calculateActualPercentage(data[3], data[1]), ((data[4] / 100) - this.calculateActualPercentage(data[3], data[1])), (1 - (data[4] / 100))]}
                                      percent={this.gaugePercentage(data[4] / 100)}
                                      animate={false}
                                      style={chartStyle}
                                    />
                                  </div>

                                  <h4><b>{UtilsFunction.percentage(data[4] / 100)}</b></h4>
                                  <span style={{ marginBottom: '0px !important' }}>
                                    Plan:
                                    {' '}
                                    {UtilsFunction.currencyNoDecim(data[3])}
                                  </span>
                                  <br />
                                  <span style={{ marginBottom: '0px !important' }}>
                                    Projected Landing Position:
                                    {' '}
                                    {UtilsFunction.currencyNoDecim(data[2])}
                                  </span>
                                  <br />
                                  <span style={{ marginBottom: '0px !important' }}>
                                    Actuals
                                    {' '}
                                    {UtilsFunction.currencyNoDecim(data[1])}
                                    <br />
                                    <br />
                                  </span>
                                </Grid>
                              ))}
                            </Grid>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>

                  <Grid item xs={12}>
                    <Card>
                      <CardContent>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <p className="subMenu-blue text-center">Are we growing sales across the account?</p>
                          </Grid>
                          <Grid item xs={6}>
                            <Grid container spacing={2}>
                              <Grid item xs={12}>
                                <br />
                                <h4 className="text-center titleSection">Growth Last Quarter</h4>
                              </Grid>
                              <Grid item xs={6}>
                                <Card>
                                  <CardContent>
                                    <p className="text-center">{this.currentQuarter()} QoQ growth ($)</p>
                                    <p className={`text-center big-number`}>{UtilsFunction.currency(this.props.salesExecutionData.currentQoQGrowthDifference[0])}</p>
                                    <Tooltip title="Current QoQ growth ($) – Last QoQ growth ($)">
                                      <p className={`text-center small-number ${(this.props.salesExecutionData.currentQoQGrowthDifference[1] > 0) ? 'green-number' : 'red-number'}`}>
                                        {UtilsFunction.currency(this.props.salesExecutionData.currentQoQGrowthDifference[1])} 
                                        {(this.props.salesExecutionData.currentQoQGrowthDifference[1] > 0) ? <ArrowDropUp /> : <ArrowDropDown />}
                                        
                                      </p>
                                    </Tooltip>
                                  </CardContent>
                                </Card>
                              </Grid>
                              <br />
                              <Grid item xs={6}>
                                <Card>
                                  <CardContent>
                                    <p className="text-center">{this.currentQuarter()} QoQ growth (%)</p>
                                    <p className={`text-center big-number`}>{UtilsFunction.percentage(this.props.salesExecutionData.currentQoQGrowthPercentage[0])}</p>
                                    <Tooltip title="Current QoQ growth (%) – Last QoQ growth (%)">
                                      <p className={`text-center small-number ${(this.props.salesExecutionData.currentQoQGrowthPercentage[1] > 0) ? 'green-number' : 'red-number'}`}>
                                        {UtilsFunction.percentage(this.props.salesExecutionData.currentQoQGrowthPercentage[1])}
                                        {(this.props.salesExecutionData.currentQoQGrowthPercentage[1] > 0) ? <ArrowDropUp /> : <ArrowDropDown />}
                                      </p>
                                    </Tooltip>
                                  </CardContent>
                                </Card>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={6}>
                            <br />
                            <h4 className="text-center titleSection">Q4 Growth (%)</h4>
                            <Bar
                              datasetIdKey="id"
                              ref={this.selectorRef}
                              data={{
                                labels: this.props.salesExecutionData.currentQoQGrowthColumn,
                                datasets: this.props.salesExecutionData.currentQoQGrowth,
                              }}
                              options={{
                                indexAxis: 'y',
                              }}
                              onClick={this.handleClickInside}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <br />
                            <h4 className="text-center titleSection">Quarterly Growth ($)</h4>
                            <Bar
                              datasetIdKey="id"
                              data={{
                                labels: this.props.salesExecutionData.salesGILCDifferenceColumns,
                                datasets: this.props.salesExecutionData.salesGILCDifference,
                              }}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <br />
                            <h4 className="text-center titleSection">Quarterly Growth (%)</h4>
                            <Line
                              datasetIdKey="id"
                              data={{
                                labels: this.props.salesExecutionData.salesGILCPercenrtageColumns,
                                datasets: this.props.salesExecutionData.salesGILCPercentage,
                              }}
                            />
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value={this.state.tabSelected} index={1}>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Card>
                      <CardContent>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <p className="subMenu-blue text-center">Are we funding the business with smaller deals?</p>
                          </Grid>
                          <Grid item xs={6}>
                            <h5 className="text-center">Qualified Pipeline</h5>
                            <br />
                            <Bar
                              datasetIdKey="id"
                              data={{
                                labels: this.props.salesExecutionData.qualifiedPipelineColumn,
                                datasets: this.props.salesExecutionData.qualifiedPipeline,
                              }}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <h5 className="text-center">Unqualified Pipeline</h5>
                            <br />
                            <Bar
                              datasetIdKey="id"
                              data={{
                                labels: this.props.salesExecutionData.unqualifiedPipelineColumn,
                                datasets: this.props.salesExecutionData.unqualifiedPipeline,
                              }}
                            />
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value={this.state.tabSelected} index={2}>
              {/* Accelerate to Close */}
              <Button variant="contained" onClick={this.handleClickBack}>Back</Button>
              <Bar
                datasetIdKey="id"
                ref={this.selectorRef}
                data={{
                  labels: this.state.testColumn,
                  datasets: this.state.testData,
                }}
                options={{
                  indexAxis: 'y',
                }}
                onClick={this.handleClickInside}
              />

            <Bar
              options={{
                indexAxis: 'y',
                scales: {
                  x: {
                    stacked: true,
                  },
                  y: {
                    stacked: true,
                  },
                },
                // plugins: {
                //   tooltip: {
                //     callbacks: {
                //       label: function(context) {
                //         let label = context.dataset.label || '';
                //         console.log(context.label);
                //         console.log(context.datasetIndex);
                //         console.log('--------------------------------------------');
                //         return label;
                //       }
                //     }
                //   }
                // }
              }}
              data={{
                labels: this.state.testColumnStacked,
                datasets: this.state.testDataStacked,
              }} />

              <br />
            </TabPanel>
          </Box>
        </Grid>
      </Grid>
    );
  }
}

SalesExecution.propTypes = {
  salesExecutionData: PropTypes.objectOf(PropTypes).isRequired,
  getData: PropTypes.func.isRequired,
  fiscalQuarter: PropTypes.string.isRequired,
  market: PropTypes.string.isRequired,
  service: PropTypes.string.isRequired,
  groupBy: PropTypes.string.isRequired,
  portfolio: PropTypes.string.isRequired,
};

export default SalesExecution;
