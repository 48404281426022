import React from 'react';
import {
  Grid, Card, CardContent, Skeleton,
} from '@mui/material';

import 'chart.js/auto';
import { Line } from 'react-chartjs-2';

import '../../App.css';

class Overview extends React.Component {
  render() {
    return (
      <Grid container spacing={2} sx={{ marginTop: '20px' }}>
        <Grid item xs={4}>
          <Card raised={false} sx={{ minWidth: 275 }}>
            <CardContent>
              <h5>Description</h5>
              <p>An at-a-glance summary view of the current state of key account descriptors, such as revenue, CCI%, headcount, locations, project list, etc.</p>
              <h5>Purpose</h5>
              <p>Provide leadership with a quick reference site to stay current on the size and state of the account.</p>
              <h5>Scope</h5>
              <p>Account-wide with ability to see detail at the portfolio level down to the program level.</p>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={8}>
          <Card raised={false} sx={{ minWidth: 275 }}>
            <CardContent>
              <h5>How fast are we growing revenue?  Where has growth slowed/accelerated?</h5>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Line
                    datasetIdKey="id"
                    data={{
                      labels: ['Jun', 'Jul', 'Aug'],
                      datasets: [
                        {
                          id: 1,
                          label: '',
                          data: [5, 6, 7],
                          borderColor: 'rgb(255, 99, 132)',
                          backgroundColor: 'rgba(255, 99, 132, 0.5)',
                        },
                        {
                          id: 2,
                          label: '',
                          data: [3, 2, 1],
                          borderColor: 'rgb(53, 162, 235)',
                          backgroundColor: 'rgba(53, 162, 235, 0.5)',
                        },
                      ],
                    }}
                  />
                  <br />
                  <br />
                  <p>Current Revenue by program, by time, by SG, by location, by MU</p>
                </Grid>
                <Grid item xs={6}>
                  <Skeleton />
                  <Skeleton animation="wave" />
                  <Skeleton animation={false} />
                  <Skeleton />
                  <Skeleton animation="wave" />
                  <Skeleton animation={false} />
                  <Skeleton />
                  <Skeleton animation="wave" />
                  <Skeleton animation={false} />
                  <Skeleton />
                  <br />
                  <br />
                  <p>Current Headcount by program, by time, by SG, by location, by MU</p>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={4}>
          <Card raised={false} sx={{ minWidth: 275 }}>
            <CardContent>
              <h5>How profitable are we?</h5>
              <Skeleton />
              <Skeleton animation="wave" />
              <Skeleton animation={false} />
              <Skeleton />
              <Skeleton animation="wave" />
              <Skeleton animation={false} />
              <Skeleton />
              <Skeleton animation="wave" />
              <Skeleton animation={false} />
              <Skeleton />
              <br />
              <br />
              <p>Current CCI% by program, by time, by SG, by location, by MU</p>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={4}>
          <Card raised={false} sx={{ minWidth: 275 }}>
            <CardContent>
              <h5>What services are we providing?</h5>
              <Skeleton />
              <Skeleton animation="wave" />
              <Skeleton animation={false} />
              <Skeleton />
              <Skeleton animation="wave" />
              <Skeleton animation={false} />
              <Skeleton />
              <Skeleton animation="wave" />
              <Skeleton animation={false} />
              <Skeleton />
              <br />
              <br />
              <br />
              <p>Current List of programs</p>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={4}>
          <Card raised={false} sx={{ minWidth: 275 }}>
            <CardContent>
              <h5>Where are we serving Meta?</h5>
              <Skeleton />
              <Skeleton animation="wave" />
              <Skeleton animation={false} />
              <Skeleton />
              <Skeleton animation="wave" />
              <Skeleton animation={false} />
              <Skeleton />
              <Skeleton animation="wave" />
              <Skeleton animation={false} />
              <Skeleton />
              <br />
              <br />
              <p>Current List/Map of locations with program and headcount data</p>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    );
  }
}

export default Overview;
