import React from 'react';
// import PropTypes from 'prop-types';
// import { Accordion } from 'react-bootstrap';
import {
  Button, Grid,
} from '@mui/material';
import { MailOutline } from '@mui/icons-material';
// import { CheckCircle, Cancel, MailOutline } from '@mui/icons-material';
import { Link } from 'react-router-dom';

import * as constants from '../constants';

import '../App.css';
import '../styles/homePage.css';
import 'bootstrap/dist/css/bootstrap.min.css';

// import Notifications from './commonComponents/notifications';
import IntroductionBanner from './commonComponents/introductionBanner';
// import MyReport from './commonComponents/myReport';
// import MyWatchlist from './commonComponents/myWatchlist';

class HomePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  // getIcon(text) {
  //   switch (text) {
  //     case '/':
  //       return (<CheckCircle fontSize="small" style={{ color: '#5f9ea0' }} />);
  //     case 'x':
  //       return (<Cancel fontSize="small" style={{ color: '#707070' }} />);
  //     default:
  //       return (text);
  //   }
  // }

  mainBody() {
    return (
      <div>
        <IntroductionBanner />

        <Grid container spacing={5} id="definition" className="flex flex-center text-left">
          <Grid item xs={12} md={5} className="padding_right_20">
            <h2><b>{constants.homeText.whatIsTextTitle}</b></h2>
            <p>{constants.homeText.whatIsTextSubtitle}</p>
          </Grid>

          <Grid item xs={12} md={7} className="box flex">
            <Grid container>
              <Grid item xs sx={{ minHeight: '200px' }} className="box-border flex flex-column flex-space-between">
                {/* <Grid item xs sx={{ minHeight: '400px' }} className="box-border flex flex-column flex-space-between"> */}
                <div>
                  <h3>
                    <b className="margin-right-10">Curated Experience</b>
                  </h3>
                  <p>View pages and metrics tailored to answer key business questions about the Meta account</p>
                </div>
                <Link to="/analysis">
                  <Button className="button-purple-light" variant="contained" disableElevation>Start Now</Button>
                </Link>
              </Grid>
              {/* <Grid item xs sx={{ minHeight: '400px' }} className="box-padding flex flex-column flex-space-between">
                <div>
                  <h3><b>Report Builder</b></h3>
                  <p>Create and save your own custom Reports using the Account Insights library of metrics</p>
                </div>
                <Button className="button-whiteblue-light" variant="outlined" disableElevation onClick={() => { console.log('Create a Report'); }}>Create a Report</Button>
              </Grid> */}
            </Grid>
          </Grid>
        </Grid>

        {/* <Grid container id="accordionSections">
          {constants.homeOptions.map((option, index) => (
            <Grid container className="accordionSection" key={option.name}>
              <Grid item xs={6}>
                <h3 className="text-left"><b>{option.name}</b></h3>
              </Grid>
              {(index === 0) ? (
                <>
                  <Grid item className="text-center" xs={3}><h6>Curated Experience</h6></Grid>
                  <Grid item className="text-center" xs={3}><h6>Report Builder</h6></Grid>
                </>
              ) : ''}
              <Grid container>
                <Grid item xs={12}>
                  <Accordion>
                    {option.tabs.map((tab, index2) => (
                      <div key={tab.tabName}>
                        <Accordion.Item eventKey={index2}>
                          <Accordion.Header>{tab.tabName}</Accordion.Header>
                          <Accordion.Body>
                            <Grid container>
                              <Grid item xs={12}>
                                {tab.subTabs.map((subtabs) => (
                                  <Grid container key={subtabs.name}>
                                    {(subtabs.subtabs.length > 0)
                                      ? (
                                        <Grid container>
                                          <Grid item xs={12}>
                                            <Accordion>
                                              <Accordion.Item eventKey={index2}>
                                                <Accordion.Header>{subtabs.name}</Accordion.Header>
                                                <Accordion.Body>
                                                  <Grid container>
                                                    <Grid item xs={12}>
                                                      {subtabs.subtabs.map((subsubtab) => (
                                                        <Grid container key={subsubtab.name}>
                                                          <Grid item xs={6}><b className="fontsize-14">{subsubtab.name}</b></Grid>
                                                          <Grid item className="text-center fontsize-14" xs={3}>{this.getIcon(subsubtab.option1)}</Grid>
                                                          <Grid item className="text-center fontsize-14" xs={3}>{this.getIcon(subsubtab.option2)}</Grid>
                                                        </Grid>
                                                      ))}
                                                    </Grid>
                                                  </Grid>
                                                </Accordion.Body>
                                              </Accordion.Item>
                                            </Accordion>
                                          </Grid>
                                        </Grid>
                                      ) : (
                                        <>
                                          <Grid item xs={6}><b className="fontsize-14">{subtabs.name}</b></Grid>
                                          <Grid item className="text-center fontsize-14" xs={3}>{this.getIcon(subtabs.option1)}</Grid>
                                          <Grid item className="text-center fontsize-14" xs={3}>{this.getIcon(subtabs.option2)}</Grid>
                                        </>
                                      )}
                                  </Grid>
                                ))}
                              </Grid>
                            </Grid>
                          </Accordion.Body>
                        </Accordion.Item>
                      </div>
                    ))}
                  </Accordion>
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Grid> */}

        {/* <Grid container id="myDashboard">
          <h2>My Dashboard</h2>
          <Grid item xs={12} className="margin-bottom-40">
            <MyReport />
          </Grid>
          <Grid item xs={12}>
            <MyWatchlist />
          </Grid>
        </Grid> */}

        <Grid container id="contacts" className="flex">
          <h2><b>Account Insights Contacts</b></h2>

          <Grid container>
            <Grid item xs={12}>
              <h4><b>Meta Executive Sponsors</b></h4>
            </Grid>
            {constants.clientContactsSponsors.map((contact) => (
              <Grid item xs={4} className="flex flex-center flex-column wrapper" key={contact.name}>
                <div className="relative">
                  <img src={contact.img} height="170" alt="logo" />
                  <a href={contact.mailto}>
                    <div className="circle">
                      <MailOutline />
                    </div>
                  </a>
                </div>
                <h6><b>{contact.name}</b></h6>
                <p className="fontsize-16">{contact.text}</p>
              </Grid>
            ))}
          </Grid>

          <Grid container>
            <Grid item xs={12}>
              <h4><b>Meta Reporting & Analytics Contacts</b></h4>
            </Grid>
            {constants.clientContacts.map((contact) => (
              <Grid item xs={4} className="flex flex-center flex-column wrapper" key={contact.name}>
                <div className="relative">
                  <img src={contact.img} height="150" alt="logo" />
                  <a href={contact.mailto}>
                    <div className="circle">
                      <MailOutline />
                    </div>
                  </a>
                </div>
                <h6><b>{contact.name}</b></h6>
                <p className="fontsize-14">{contact.text}</p>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </div>
    );
  }

  render() {
    return (
      <div>
        { this.mainBody() }
      </div>
    );
  }
}

// HomePage.propTypes = {
//   openNotifications: PropTypes.bool.isRequired,
//   notifications: PropTypes.arrayOf(PropTypes).isRequired,
//   openCloseNotifications: PropTypes.func.isRequired,
// };

export default HomePage;
