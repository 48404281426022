import React from 'react';
import PropTypes from 'prop-types';
import {
  Button, Modal, Box, Badge, Menu, MenuItem,
} from '@mui/material';
import { Navbar, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import {
  // PhoneIphone,
  HelpOutline,
  NotificationsNone,
} from '@mui/icons-material';

import * as constants from '../../constants';

import '../../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 700,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  borderTop: '8px solid #a100ff',
  boxShadow: 24,
  p: 4,
};

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openMobileAppModal: false,
      anchorEl: null,
      open: false,
    };

    this.helpClick = this.helpClick.bind(this);
  }

  mobileAppModal() {
    return (
      <Modal
        open={this.state.openMobileAppModal}
        onClose={() => this.setState({ openMobileAppModal: false })}
      >
        <Box sx={style}>
          <h3 id="unstyled-modal-title">VIP Mobile App</h3>
          <p id="unstyled-modal-description">VIP Mobile App is now available and can be installed from Intune Company Portal. Please follow the links below:</p>
          <a href="/">VIP Mobile App installation video for Android</a>
          <br />
          <br />
          <a href="/">VIP Mobile App installation video for IOS</a>
          <br />
          <br />
          <Button variant="text" onClick={() => { console.log('Download User Guide'); }}>DOWNLOAD USER GUIDE</Button>
        </Box>
      </Modal>
    );
  }

  helpClick(e) {
    this.setState({
      anchorEl: e.currentTarget,
      open: true,
    });
  }

  render() {
    return (
      <div>
        <Navbar expand="lg">
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav defaultActiveKey={window.location.pathname} className="me-auto">
              <Navbar.Brand className="flex flex-center padding_0">
                <img src="imgs/logos/acn-logo.png" height="45" alt="logo" />
              </Navbar.Brand>
              {constants.navOptions.map((option) => (
                <Link to={option.url} onClick={() => { this.props.updatePath(option.url); }} key={option.name}>
                  <Nav.Link href={option.url} key={option.name} className="padding_0830 flex flex-center">
                    {option.name}
                    {' '}
                  </Nav.Link>
                </Link>
              ))}
            </Nav>
            <Nav>
              {/* <div className="margin-right-20 flex flex-center">
                <Button disabled className="button-purple button-disabled" onClick={() => this.setState({ openMobileAppModal: true })} variant="contained" disableElevation>
                  <PhoneIphone className="margin-right-10" />
                  <span>API Mobile App</span>
                </Button>
              </div> */}
              <div className="flex flex-column flex-center margin-right-20 cursor-pointer" onClick={() => { console.log('Help'); }}>
                <div
                  aria-controls={this.state.open ? 'basic-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={this.state.open ? 'true' : undefined}
                  onClick={this.helpClick}
                  className="flex flex-column flex-center"
                >
                  <HelpOutline />
                  <span className="fontsize-12">Help</span>
                </div>
                <Menu
                  id="basic-menu"
                  anchorEl={this.state.anchorEl}
                  open={this.state.open}
                  onClose={() => { this.setState({ open: false }); }}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                >
                  {/* <MenuItem>Introduction</MenuItem> */}
                  {/* <MenuItem>FAQ</MenuItem> */}
                  <MenuItem>
                    <a href="mailto:meta.reporting@accenture.com?subject=LT Insights Platform Contact Request">Email Us</a>
                  </MenuItem>
                </Menu>
              </div>
              <div className="flex cursor-pointer">
                <Link to="/notifications" className="padding_0 flex flex-center notification">
                  <Badge badgeContent={this.props.notificationsLength} color="primary">
                    <NotificationsNone sx={{ fontSize: 30 }} />
                  </Badge>
                </Link>
              </div>
            </Nav>
          </Navbar.Collapse>
        </Navbar>

        {this.mobileAppModal()}
      </div>
    );
  }
}

Header.propTypes = {
  notificationsLength: PropTypes.number.isRequired,
  updatePath: PropTypes.func.isRequired,
};

export default Header;
