export function authenticateWithCognito(ENV) {
    // export function authenticateWithCognito() {
      const crypto = window.crypto || window.msCrypto;
      const array = new Uint32Array(1);
      crypto.getRandomValues(array);
      const GENERATED_NUM = array[0];
    
      if (ENV === 'production') {
        const BaseUrl = 'accountinsights.accenture.com';
        window.location.replace(`https://login.microsoftonline.com/e0793d39-0939-496d-b129-198edd916feb/oauth2/v2.0/authorize?client_id=af873c59-2cff-40b0-8517-9ffe43c66692&response_type=id_token&redirect_uri=https://${BaseUrl}&scope=openid+profile&nonce=${GENERATED_NUM}`);
      } else {
        const BaseUrl = 'staging.accountinsights.accenture.com';
        window.location.replace(`https://login.microsoftonline.com/f3211d0e-125b-42c3-86db-322b19a65a22/oauth2/v2.0/authorize?client_id=54811b25-b003-4c44-a3bf-cea5b2bfebed&response_type=id_token&redirect_uri=https://${BaseUrl}&scope=openid+profile&nonce=${GENERATED_NUM}`);
      }
    }
    
    export function currency(number) {
      const dollarUS = Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      });
    
      return dollarUS.format(number);
    }
    
    export function currencyNoDecim(number) {
      const dollarUS = Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
      });
    
      return dollarUS.format(Math.trunc(number));
    }
    
    export function percentage(number) {
      const num = number * 100;
      const percentageCurrency = `${parseFloat(num).toFixed(1)}%`;
      return percentageCurrency;
    }
    
    export function def() {
      console.log('Default');
    }
    