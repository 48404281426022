import React, { useState, useEffect } from 'react';
import {
  Grid, FormControl, InputLabel, Select, MenuItem, Button,
} from '@mui/material';
import PropTypes from 'prop-types';

import '../../App.css';
import '../../styles/informationPage.css';

function Filters(props) {
  const [fiscalQuarter, setFiscalQuarter] = useState('');
  const [market, setMarket] = useState('');
  const [portfolio, setPortfolio] = useState('');
  const [service, setService] = useState('');
  const [groupBy, setGroupBy] = useState('');

  useEffect(() => {
    setGroupBy(props.groupBy);
  }, [props.groupBy]);

  useEffect(() => {
    setFiscalQuarter(props.fiscalQuarter);
  }, [props.fiscalQuarter]);

  useEffect(() => {
    setMarket(props.market);
  }, [props.market]);

  useEffect(() => {
    setPortfolio(props.portfolio);
  }, [props.portfolio]);

  useEffect(() => {
    setService(props.service);
  }, [props.service]);

  const handleChange = (e) => {
    if (e.target.name === 'fiscalQuarter') {
      setFiscalQuarter(e.target.value);
    }
    if (e.target.name === 'market') {
      setMarket(e.target.value);
    }
    if (e.target.name === 'portfolio') {
      setPortfolio(e.target.value);
    }
    if (e.target.name === 'service') {
      setService(e.target.value);
    }
  };

  const changeGroupBy = (groupByValue) => {
    setGroupBy(groupByValue);

    props.getData(fiscalQuarter, market, portfolio, service, groupByValue);
  };

  const clearFilters = () => {
    setFiscalQuarter('');
    setMarket('');
    setPortfolio('');
    setService('');

    props.clearFilters();
  };

  return (
    <Grid container>
      <Grid item xs={12} sx={{ paddingTop: '5px !important' }}>
        <Grid container spacing={2}>
          <Grid item xs={2}>
            <FormControl fullWidth variant="filled" size="small">
              <InputLabel id="demo-simple-select-label">Fiscal Period</InputLabel>
              <Select
                name="fiscalQuarter"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={fiscalQuarter}
                label="Fiscal Period"
                onChange={handleChange}
              >
                <MenuItem value="">All</MenuItem>
                {props.fiscalQuarterOptions.map((option) => (
                  <MenuItem value={option}>{option}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={2}>
            <FormControl fullWidth variant="filled" size="small">
              <InputLabel id="demo-simple-select-label">Market</InputLabel>
              <Select
                name="market"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={market}
                label="Market"
                onChange={handleChange}
              >
                <MenuItem value="">All</MenuItem>
                {props.marketOptions.map((option) => (
                  <MenuItem value={option}>{option}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <FormControl fullWidth variant="filled" size="small">
              <InputLabel id="demo-simple-select-label">Portfolio</InputLabel>
              <Select
                name="portfolio"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={portfolio}
                label="Portfolio"
                onChange={handleChange}
              >
                <MenuItem value="">All</MenuItem>
                {props.portfolioOptions.map((option) => (
                  <MenuItem value={option}>{option}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={2}>
            <FormControl fullWidth variant="filled" size="small">
              <InputLabel id="demo-simple-select-label">Service</InputLabel>
              <Select
                name="service"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={service}
                label="Service"
                onChange={handleChange}
              >
                <MenuItem value="">All</MenuItem>
                {props.serviceOptions.map((option) => (
                  <MenuItem value={option}>{option}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={2} className="flex flex-center">
            <Button variant="contained" size="small" className="fontsize-14 button-filter-active" onClick={() => { props.getData(fiscalQuarter, market, portfolio, service, groupBy); }} sx={{ width: '100% !important' }}>Search</Button>
            {/* <Button variant="contained" size="small" className="button-purple" onClick={() => { props.getData(fiscalQuarter, market, portfolio, service, groupBy); }} sx={{ width: '100% !important' }}>Search</Button> */}
          </Grid>
          <Grid item xs={1} className="flex flex-center">
            <Button variant="outlined" size="small" className="fontsize-14 button-filter" style={{ backgroundColor: '#FFF !important' }} onClick={() => { clearFilters(); }} sx={{ width: '100% !important' }}>Clear</Button>
            {/* <Button variant="outlined" size="large" className={`${props.type} button-filter fontsize-14`} onClick={() => { clearFilters(); }} sx={{ width: '100% !important' }}>Clear</Button> */}
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} className="flex flex-center flex-column" sx={{ paddingTop: '15px !important' }}>
        <div>
          <b className="fontsize-14">View Data By</b>
        </div>
        <div>
          <Button variant="outlined" size="small" className={`${props.type} fontsize-14 ${(groupBy === 'portfolio') ? 'button-filter-active' : 'button-filter'}`} onClick={() => { changeGroupBy('portfolio'); }}>Portfolio</Button>
          <Button variant="outlined" size="small" className={`${props.type} fontsize-14 ${(groupBy === 'market') ? 'button-filter-active' : 'button-filter'}`} onClick={() => { changeGroupBy('market'); }}>Market</Button>
          <Button variant="outlined" size="small" className={`${props.type} fontsize-14 ${(groupBy === 'service_group') ? 'button-filter-active' : 'button-filter'}`} onClick={() => { changeGroupBy('service_group'); }}>Service</Button>
        </div>
      </Grid>
    </Grid>
  );
}

Filters.propTypes = {
  marketOptions: PropTypes.arrayOf(PropTypes.string).isRequired,
  serviceOptions: PropTypes.arrayOf(PropTypes.string).isRequired,
  portfolioOptions: PropTypes.arrayOf(PropTypes.string).isRequired,
  fiscalQuarterOptions: PropTypes.arrayOf(PropTypes.string).isRequired,
  getData: PropTypes.func.isRequired,
  clearFilters: PropTypes.func.isRequired,
  groupBy: PropTypes.string.isRequired,
  fiscalQuarter: PropTypes.string.isRequired,
  market: PropTypes.string.isRequired,
  portfolio: PropTypes.string.isRequired,
  service: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

export default Filters;
