import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Button } from '@mui/material';
import { Link } from 'react-router-dom';

import '../../App.css';

class Footer extends React.Component {
  render() {
    return (
      <footer className="flex flex-center text-left">
        <Grid container>
          <Grid item xs={3} className="text-center">
            <img src="imgs/logos/acn1.png" alt="Accenture Logo" />
          </Grid>
          <Grid item xs={2} />
          <Grid item xs={3}>
            <h6><b>HELP</b></h6>
            {/* <a href="#">Introduction</a>
            <br />
            <br />
            <a href="#">FAQ</a>
            <br />
            <br /> */}
            <Link to="/contact-us">
              <a href="/contact-us" className="cursor-pointer">Contact Us</a>
            </Link>
          </Grid>
          <Grid item xs={4}>
            <p>Got a question, comment or idea?</p>
            <Button className="button-footer text-center fontsize-12 cursor-pointer" variant="outlined" onClick={this.props.openFeedbackModal}>Give us Feedback</Button>
            <p>
              <span className="fontsize-12">Copyright © 2022 Accenture. All rights reserved. Accenture confidential. For internal use only.</span>
            </p>
          </Grid>
        </Grid>
      </footer>
    );
  }
}

Footer.propTypes = {
  openFeedbackModal: PropTypes.func.isRequired,
};

export default Footer;
